<template>
  <v-dialog
    v-model="showSearchJobDialog"
    width="800"
    @click:outside="closeSearchJobDialog"
  >
    <template v-slot:activator="{ on }">
      <v-badge
        :color="unFilteredinActiveAssignedItemsCount > 0 ? 'error' : ''"
        :content="unFilteredinActiveAssignedItemsCount"
        overlap
        offset-x="20"
      >
        <v-btn
          id="addJobButton"
          class="font-weight--600"
          color="button-background"
          depressed
          rounded
          height="40"
          v-on="on"
          aria-live="polite"
          :aria-label="
            'search for a job or add assigned tasks and projects. ' +
            (unFilteredinActiveAssignedItemsCount > 0
              ? `You have ${unFilteredinActiveAssignedItemsCount} assigned jobs`
              : '')
          "
        >
          <v-icon size="20" color="primary">mdi-format-list-bulleted</v-icon>
          <span class="ml-2">
            {{ $t('timesheetUI.Btns.BtnJobList') }}
          </span>
        </v-btn>
      </v-badge>
    </template>
    <FocusLock returnFocus>
      <v-card>
        <v-card-title class="justify-space-between px-6 py-5">
          <h1 class="text-h2 primary--text">
            {{ $t('timesheetUI.SearchJobs.SearchJobsPanelTitle') }}
          </h1>
          <div>
            <v-btn
              text
              icon
              color="primary"
              @click="closeSearchJobDialog"
              aria-label="Close Timesheet Search"
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-divider role="presentation"></v-divider>

        <v-card-text class="px-6 py-5">
          <v-text-field
            :placeholder="$t('timesheetUI.SearchJobs.SearchBoxPlaceholder')"
            full-width
            outlined
            dense
            id="searchInputBox"
            @input="searchDebounce($event)"
            append-icon="mdi-magnify"
            v-model="searchQuery"
            hide-details
          ></v-text-field>

          <div>
            <template>
              <v-tabs v-model="activeTab" class="pt-3 mb-4" height="auto">
                <template>
                  <v-tab
                    v-for="filter in filters"
                    :key="filter.key"
                    @change="agencyMode = filter.value"
                  >
                    <span>{{ filter.title }}</span>
                    <v-badge
                      color="button-background text--primary font-weight--600"
                      light
                      v-if="filter.key === 'assignedJobs' && shelvedJobsCount"
                      :content="shelvedJobsCount"
                      inline
                    ></v-badge>
                  </v-tab>
                </template>
              </v-tabs>
            </template>

            <div
              class="d-flex justify-space-between mb-4 text-caption font-weight-medium"
              v-if="totalResultCount > 0 && jobListAssignedJobsSearchKeyword"
            >
              <div>
                {{
                  isShowAllTab
                    ? shelvedJobsCount + totalResultCount
                    : totalResultCount
                }}
                {{ $t('timesheetUI.SearchJobs.ResultsFoundLabel') }}
              </div>
              <div>
                {{
                  $t('timesheetUI.SearchJobs.ShowingResultsLabel')
                    .replace(
                      '${pagNumber}',
                      isShowAllTab
                        ? shelvedJobsCount + searchResults.length
                        : searchResults.length
                    )
                    .replace(
                      '${totalResults}',
                      isShowAllTab
                        ? shelvedJobsCount + totalResultCount
                        : totalResultCount
                    )
                }}
              </div>
            </div>
          </div>

          <div class="text-center" v-if="showLoading && !searchResults.length">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            ></v-progress-circular>
          </div>

          <transition name="fade">
            <div class="timesheet-search-job--items" ref="dialogContainer">
              <div v-if="activeTab === filterTabs.assignedJobs">
                <AssignedJobCard
                  v-for="(job, i) in shelvedJobs"
                  :key="`${job.job_ID}-${i}-a`"
                  :job="job"
                  :closeSearchJobDialog="closeSearchJobDialog"
                  :showSearchJobDialog="showSearchJobDialog"
                />
              </div>

              <div
                v-if="
                  activeTab !== filterTabs.assignedJobs &&
                  (totalResultCount > 0 || shelvedJobs.length > 0)
                "
              >
                <div v-if="activeTab === filterTabs.showAll">
                  <AssignedJobCard
                    v-for="(job, i) in shelvedJobs"
                    :key="`${job.job_ID}-${i}-a`"
                    :job="job"
                    :closeSearchJobDialog="closeSearchJobDialog"
                    :showSearchJobDialog="showSearchJobDialog"
                  />
                </div>

                <v-card
                  v-for="(job, i) in searchResults"
                  :key="`${job.job_ID}-${i}`"
                  outlined
                  class="mb-3"
                  :class="{
                    'border-color--error': duplicateJobsList.includes(
                      job.job_ID
                    ),
                  }"
                >
                  <v-card-text class="px-5 py-3">
                    <div class="d-flex align-center gap-6">
                      <div class="flex-grow-1 font-size--10">
                        <div
                          :class="[
                            duplicateJobsList.includes(job.job_ID)
                              ? 'error--text'
                              : 'font-color--primary',
                            'font-weight--600 mb-2 font-size--12',
                          ]"
                        >
                          <span>{{ job.job_Name }}</span>
                        </div>

                        <div class="d-flex flex-wrap secondary--text">
                          <span class="mr-1">{{ job.client_Name }}</span> •
                          <span class="mx-1">{{ job.agency_Name }}</span> •
                          <span class="ml-1">{{ job.job_ID }}</span>
                        </div>
                        <div
                          v-if="job.crossCompanyRuleTypeName"
                          class="accent--text my-1"
                        >
                          <span class="mr-1">•</span>
                          <span class="font-size--10">{{
                            job.crossCompanyRuleTypeName
                          }}</span>
                          <v-icon
                            class="cross-company-rule__label"
                            color="accent"
                            :size="10"
                            >mdi-star</v-icon
                          >
                        </div>

                        <template v-if="job.selfAssignmentWithManageTasking">
                          <PPMTaskDropdown
                            :job="job"
                            @change="setSelfAssignementSelectedTasks"
                            :error="ppmValid[job.job_ID]"
                          ></PPMTaskDropdown>
                        </template>

                        <div
                          v-if="ppmValid[job.job_ID]"
                          id="ppmTaskError"
                          class="job-validation-message error--text mt-2"
                          role="alert"
                          aria-live="assertive"
                        >
                          {{ $t('timesheetUI.SearchJobs.toAddAJob') }}
                        </div>

                        <template v-if="job.isTaskAgency">
                          <AltairTaskDropdown
                            :job="job"
                            @change="setSelfAssignementSelectedTasks"
                            :error="altairTaskValid[job.job_ID]"
                          ></AltairTaskDropdown>
                        </template>

                        <div
                          v-if="altairTaskValid[job.job_ID]"
                          id="altairTaskError"
                          class="job-validation-message error--text mt-2"
                          role="alert"
                          aria-live="assertive"
                        >
                          {{ $t('timesheetUI.SearchJobs.toAddAJob') }}
                        </div>

                        <div
                          v-if="duplicateJobsList.includes(job.job_ID)"
                          class="font-size--10 duplicate-job__warning-msg mt-2"
                        >
                          <span v-if="!isJobSelfAssignment">
                            {{ $t('timesheetUI.SearchJobs.DuplicateAssigned') }}
                          </span>
                          <span v-else>
                            {{
                              $t('timesheetUI.SearchJobs.DuplicateSelfAssigned')
                            }}
                          </span>
                        </div>
                      </div>
                      <v-btn
                        v-if="duplicateJobsList.includes(job.job_ID)"
                        depressed
                        text
                        @click="cancelDuplicateJobWarning(job.job_ID)"
                      >
                        <span
                          v-if="
                            (job.selfAssignmentWithManageTasking &&
                              isJobSelfAssignment) ||
                            (!job.selfAssignmentWithManageTasking &&
                              job.isTaskAgency)
                          "
                          class="d-flex flex-column align-center"
                        >
                          <v-icon class="duplicate-job-cancel-btn-icon"
                            >mdi-close</v-icon
                          >
                          <span class="font-size--10">{{
                            $t('timesheetUI.SearchJobs.CantBeAddToTimesheet')
                          }}</span>
                        </span>
                        <span v-else class="d-flex flex-column align-center">
                          <v-icon class="duplicate-job-cancel-btn-icon"
                            >mdi-close</v-icon
                          >
                          <span class="font-size--10">{{
                            $t('timesheetUI.Btns.BtnCancel')
                          }}</span>
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="!duplicateJobsList.includes(job.job_ID)"
                        depressed
                        text
                        small
                        @click="addJobToTimesheet(job)"
                        class="pl-1 pr-2 font-weight--600"
                        :disabled="loading"
                      >
                        <span class="d-flex align-center">
                          <v-icon color="primary" :size="18">mdi-plus</v-icon>
                          <span
                            :aria-label="`${job.job_Name}, ${$t(
                              'timesheetUI.SearchJobs.BtnAddToTimesheet'
                            )} job`"
                            >{{
                              $t('timesheetUI.SearchJobs.BtnAddToTimesheet')
                            }}</span
                          >
                        </span>
                      </v-btn>
                      <v-btn
                        v-else-if="
                          !isJobSelfAssignment &&
                          !(
                            !job.selfAssignmentWithManageTasking &&
                            job.isTaskAgency
                          )
                        "
                        depressed
                        text
                        @click="addJobToTimesheet(job, true)"
                        :disabled="loading"
                      >
                        <span class="d-flex flex-column align-center">
                          <v-icon color="primary">mdi-plus</v-icon>
                          <span class="font-size--10">{{
                            $t('timesheetUI.SearchJobs.ConfirmNewRow')
                          }}</span>
                        </span>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>

                <div
                  v-if="searchResults.length !== totalResultCount"
                  class="pa-6"
                  v-intersect.quiet="{
                    handler: timesheetSearchInfiniteLoad,
                  }"
                >
                  <div v-if="showLoadingSearchResult" class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="50"
                    ></v-progress-circular>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  !showLoading &&
                  ((activeTab === filterTabs.assignedJobs &&
                    !shelvedJobs.length) ||
                    (activeTab === filterTabs.showAll &&
                      !totalResultCount &&
                      !shelvedJobs.length) ||
                    (activeTab !== filterTabs.assignedJobs &&
                      activeTab !== filterTabs.showAll &&
                      !totalResultCount))
                "
              >
                <div
                  class="font-weight--600 mt-5 mb-6 primary--text"
                  tabindex="0"
                  @focus="addFocus($event)"
                  @blur="removeFocus($event)"
                >
                  <span
                    v-if="searchQuery.length > 0"
                    v-html="
                      $t('timesheetUI.SearchJobs.NoResultsTitle').replace(
                        '${searchQuery}',
                        searchQuery
                      )
                    "
                  ></span>
                  <span
                    v-else
                    v-html="$t('timesheetUI.SearchJobs.NoJobsFound')"
                  ></span>
                </div>
                <div
                  tabindex="0"
                  @focus="addFocus($event)"
                  @blur="removeFocus($event)"
                  class="primary--text lh-20"
                >
                  <span
                    v-html="$t('timesheetUI.SearchJobs.NoResultsTips')"
                  ></span>
                </div>
              </div>
            </div>
          </transition>
        </v-card-text>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import AssignedJobCard from './search-job/AssignedJobCard.vue'
import PPMTaskDropdown from './search-job/PPMTaskDropdown.vue'
import AltairTaskDropdown from './search-job/AltairTaskDropdown.vue'
import { debounce } from 'lodash'
import {
  clientMicroServiceGet,
  clientMicroServicePost,
} from '@apis/clientService'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import i18n from '@Shared/i18n'
import FocusLock from 'vue-focus-lock'
// import moment from 'moment'

export default {
  name: 'SearchJob',
  components: {
    FocusLock,
    AssignedJobCard,
    PPMTaskDropdown,
    AltairTaskDropdown,
  },
  data() {
    return {
      showSearchJobDialog: false,
      activeFilter: 'showAll',
      filters: i18n.t('timesheetUI.SearchJobs.AgencyFilters', window.CULTURE),
      searchQuery: '',
      searchResults: [],
      totalResultCount: 0,
      rowsPerPage: 15,
      currentPage: 1,
      showLoadingSearchResult: false,
      agencyMode: 1,
      reset: true,
      showLoading: false,
      duplicateJobsList: [],
      isJobSelfAssignment: false,
      activeTab: 1,
      selectedTasks: {},
      PPMTaskId: '',
      AltairTaskId: '',
      selfAssignementSelectedTasks: {},
      ppmValid: {},
      altairTaskValid: {},
    }
  },
  methods: {
    searchDebounce: debounce(function (query) {
      this.searchQuery = query
    }, 300),
    returnFocusToAddJobButton() {
      const button = document.getElementById('addJobButton')
      if (button) {
        setTimeout(() => {
          button.focus()
        }, 500)
      }
    },
    addFocus(event) {
      event.target.classList.add('focus-outline')
    },
    removeFocus(event) {
      event.target.classList.remove('focus-outline')
    },

    timesheetSearchInfiniteLoad() {
      this.currentPage++

      // const startDate = moment(this.selectedDate.startOfWeek).isBefore(
      //   moment(this.firstAvailableContract.agencyStartDate)
      // )
      //   ? this.firstAvailableContract.agencyStartDate.split('T')[0]
      //   : this.selectedDate.startOfWeek

      const params = {
        AltairCode: this.userData.altairCode,
        ClarizenUserId: '',
        Keyword: this.searchQuery,
        CurrentPage: this.currentPage,
        ReportedDate: this.selectedDate.startOfWeek,
        StartDate: this.selectedDate.startOfWeek,
        EndDate: this.selectedDate.endOfWeek,
        ShowFacets: false,
        AgencyMode: this.agencyMode,
      }

      if (this.currentPage <= Math.ceil(this.totalResultCount / 15)) {
        this.showLoadingSearchResult = true
        clientMicroServiceGet('timesheet/Jobs/search-jobs', params)
          .then((response) => {
            this.searchResults = [
              ...this.searchResults,
              ...response.data.value.items,
            ]
          })
          .catch()
          .finally(() => {
            this.showLoadingSearchResult = false
          })
      }
    },

    addJobToTimesheet(job, overrideJob = false) {
      this.$set(this.ppmValid, job.job_ID, false)
      this.$set(this.altairTaskValid, job.job_ID, false)

      if (
        job.selfAssignmentWithManageTasking &&
        !this.selfAssignementSelectedTasks[job.job_ID]?.PPMTask
      ) {
        this.$set(this.ppmValid, job.job_ID, true)
      }

      if (
        job.isTaskAgency &&
        !this.selfAssignementSelectedTasks[job.job_ID]?.altairTask
      ) {
        this.$set(this.altairTaskValid, job.job_ID, true)
      }
      if (this.ppmValid[job.job_ID] || this.altairTaskValid[job.job_ID]) {
        return
      }

      if (!overrideJob) {
        let duplicateJob = null

        if (job.selfAssignmentWithManageTasking && job.isTaskAgency) {
          duplicateJob = this.timesheetJobs.find(
            (t) =>
              t.job_ID === job.job_ID &&
              t.clarizenTaskExternalId ===
                this.selfAssignementSelectedTasks[job.job_ID]?.PPMTask &&
              t.task ===
                this.selfAssignementSelectedTasks[job.job_ID]?.altairTask
          )
        } else if (job.isTaskAgency) {
          duplicateJob = this.timesheetJobs.find(
            (t) =>
              !t.isClarizenTask &&
              t.job_ID === job.job_ID &&
              t.task ===
                this.selfAssignementSelectedTasks[job.job_ID]?.altairTask
          )
          if (duplicateJob) {
            this.duplicateJobsList.push(duplicateJob.job_ID)
            return
          }
        } else if (job.selfAssignmentWithManageTasking) {
          duplicateJob = this.timesheetJobs.find(
            (t) =>
              t.job_ID === job.job_ID &&
              t.clarizenTaskExternalId ===
                this.selfAssignementSelectedTasks[job.job_ID]?.PPMTask
          )
        }

        if (duplicateJob) {
          this.isJobSelfAssignment =
            duplicateJob?.selfAssignmentWithManageTasking
        } else {
          this.isJobSelfAssignment = job?.selfAssignmentWithManageTasking
        }

        if (duplicateJob?.isClarizenTask) {
          this.duplicateJobsList.push(duplicateJob.job_ID)
          return
        }
      }

      const param = {
        altairCode: this.userData.altairCode,
        job_ID: job.job_ID,
        week: this.selectedDate.weekOfYear,
        year: this.selectedDate.year,
        crossCompanyRuleId: job.crossCompanyRuleId,
        agencySelfAssignmentEnabled:
          this.agencyData.agencySelfAssignmentEnabled,
        isPPMAgency: this.agencyData.isPPMAgency,
        agencyCode: job.agency,
        ver: 2,
      }

      if (this.selfAssignementSelectedTasks[job.job_ID]?.PPMTask) {
        param.isPPMTask = true
        param.ppmTaskId = this.selfAssignementSelectedTasks[job.job_ID].PPMTask

        const PPMTask = this.PPMTasksByJobId[job.job_ID].data.find(
          (task) =>
            task.value ===
            this.selfAssignementSelectedTasks[job.job_ID]?.PPMTask
        )
        param.clarizenTaskName = PPMTask.label
      }

      if (this.selfAssignementSelectedTasks[job.job_ID]?.altairTask) {
        param.isAltairTask = true
        param.altairTaskId =
          this.selfAssignementSelectedTasks[job.job_ID].altairTask

        const altairTask = this.altairTasksByJobId[job.job_ID].data.find(
          (task) =>
            task.value ===
            this.selfAssignementSelectedTasks[job.job_ID]?.altairTask
        )
        param.altairTaskName = altairTask.label
      }

      if (job.selfAssignmentWithManageTasking && this.isJobSelfAssignment) {
        param['selfAssignmentWithManageTasking'] =
          job.selfAssignmentWithManageTasking
        param['PPMTask'] = this.selfAssignementSelectedTasks[job.job_ID].PPMTask
      }

      this.setLoading(true)

      clientMicroServicePost('timesheet/Jobs/save-user-job', param)
        .then(() => {
          this.setLoading(false)
          this.loadData('jobs')
          this.showSearchJobDialog = false
          this.setPerformRemoveButtonFocus(true)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancelDuplicateJobWarning(job_ID) {
      this.duplicateJobsList = this.duplicateJobsList.filter(
        (jobId) => jobId !== job_ID
      )
    },
    resetJobSearchState() {
      this.totalResultCount = 0
      this.searchResults = []
      this.searchQuery = ''
      // this.agencyMode = 3
      // this.activeTab = 0
      // this.reset = true
      this.duplicateJobsList.length = 0
    },
    focusToSearchBox() {
      setTimeout(() => {
        const inputBox = document.getElementById('searchInputBox')
        if (inputBox) {
          inputBox.focus()
        }
      }, 100)
    },
    ...mapActions('storeTimesheets', [
      'loadData',
      'fetchPPMTasks',
      'fetchAltairTasks',
    ]),
    ...mapMutations('storeTimesheets', [
      'setPerformRemoveButtonFocus',
      'clearPPMAndAltairTasks',
      'setShowJobListPopupFromBannerLink',
      'setJobListAssignedJobsSearchKeyword',
      'hideTimeEntryDrawer',
      'setLoading',
    ]),

    closeSearchJobDialog() {
      this.showSearchJobDialog = false
      this.clearPPMAndAltairTasks()

      this.agencyMode = 1
      this.activeTab = this.filterTabs.myAgency
    },

    setSelfAssignementSelectedTasks({ jobId, taskType, selectedTask }) {
      this.$set(this.ppmValid, jobId, false)
      this.$set(this.altairTaskValid, jobId, false)
      const selectedTaskCopy = JSON.parse(
        JSON.stringify(this.selfAssignementSelectedTasks)
      )

      if (taskType === 'PPMTask') {
        if (selectedTaskCopy[jobId]) {
          selectedTaskCopy[jobId].PPMTask = selectedTask
        } else {
          selectedTaskCopy[jobId] = { PPMTask: selectedTask }
        }
      }

      if (taskType === 'altairTask') {
        if (selectedTaskCopy[jobId]) {
          selectedTaskCopy[jobId].altairTask = selectedTask
        } else {
          selectedTaskCopy[jobId] = { altairTask: selectedTask }
        }
      }

      this.selfAssignementSelectedTasks = selectedTaskCopy
      this.duplicateJobsList = []
    },
  },
  watch: {
    searchCriteria: debounce(function () {
      if (this.searchQuery && this.activeTab === this.filterTabs.assignedJobs) {
        /*
         * @todo add filter for assigned jobs
         */
      }

      // const startDate = moment(this.selectedDate.startOfWeek).isBefore(
      //   moment(this.firstAvailableContract.agencyStartDate)
      // )
      //   ? this.firstAvailableContract.agencyStartDate.split('T')[0]
      //   : this.selectedDate.startOfWeek

      if (this.searchQuery && this.activeTab !== this.filterTabs.assignedJobs) {
        this.currentPage = 1
        const params = {
          AltairCode: this.userData.altairCode,
          ClarizenUserId: '',
          Keyword: this.searchQuery,
          CurrentPage: this.currentPage,
          ReportedDate: this.selectedDate.startOfWeek,
          StartDate: this.selectedDate.startOfWeek,
          EndDate: this.selectedDate.endOfWeek,
          ShowFacets: false,
          AgencyMode: this.agencyMode,
        }

        this.showLoading = true

        clientMicroServiceGet('timesheet/Jobs/search-jobs', params)
          .then((response) => {
            this.searchResults = response.data.value.items
            this.totalResultCount = response.data.value.totalResultCount
          })
          .finally(() => {
            // this.reset = false
            this.showLoading = false
          })
      }
    }, 300),
    searchQuery(newVal) {
      this.setJobListAssignedJobsSearchKeyword(newVal)

      if (!this.searchQuery) {
        this.resetJobSearchState()
      }
    },
    showSearchJobDialog(newVal, oldVal) {
      this.resetJobSearchState()
      if (oldVal === true && newVal === false) {
        this.returnFocusToAddJobButton()
      } else {
        this.focusToSearchBox()
      }

      if (!newVal) {
        this.setShowJobListPopupFromBannerLink(false)
      }

      if (newVal) {
        this.hideTimeEntryDrawer()
      }

      document.querySelector('.timesheet-search-job--items')?.scrollTo(0, 0)
    },
    showJobListPopupFromBannerLink(newVal) {
      if (newVal) {
        this.activeTab = this.filterTabs.assignedJobs
        this.agencyMode = 3
        this.showSearchJobDialog = true
        this.setShowJobListPopupFromBannerLink(false)
      }
    },
    activeTab(newVal, oldVal) {
      if (
        newVal === this.filterTabs.assignedJobs &&
        oldVal !== this.filterTabs.assignedJobs
      ) {
        this.totalResultCount = this.shelvedJobsCount
        this.searchResults = this.shelvedJobs
      }

      if (
        newVal !== this.filterTabs.assignedJobs &&
        oldVal === this.filterTabs.assignedJobs
      ) {
        this.totalResultCount = 0
        this.searchResults = []
      }

      this.duplicateJobsList = []
      this.selfAssignementSelectedTasks = {}
      this.ppmValid = {}
      this.altairTaskValid = {}
    },

    jobListAssignedJobsSearchKeyword() {
      if (this.activeTab === this.filterTabs.assignedJobs) {
        this.totalResultCount = this.shelvedJobsCount
        this.searchResults = this.shelvedJobs
      }
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'userData',
      'agencyData',
      'timesheetSelectedJob',
      'PPMTasksByJobId',
      'altairTasksByJobId',
      'showJobListPopupFromBannerLink',
      'jobListAssignedJobsSearchKeyword',
      'loading',
      'firstAvailableContract',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDate',
      'timesheetJobs',
      'timesheetVisibilityFilter',
      'isHiddenJobsScreen',
      'shelvedJobs',
      'unFilteredinActiveAssignedItemsCount',
    ]),
    shelvedJobsCount() {
      return this.shelvedJobs.length
    },
    searchCriteria() {
      return this.searchQuery + this.agencyMode
    },
    filterTabs() {
      return Object.fromEntries(
        this.filters.map((filter, index) => [filter.key, index])
      )
    },
    isShowAllTab() {
      return this.activeTab === this.filterTabs.showAll
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tab {
  letter-spacing: normal !important;
  text-transform: none !important;
  padding: 14px 10px !important;
  border-bottom: 1px solid $border-color;
  margin: 2px 0 2px 2px;

  &:not(.v-tab--active) {
    color: $secondary !important;
  }
}

.timesheet-search-job--items {
  min-height: 300px;
  max-height: calc((100vh * 0.9) - 267px);
  margin: 0 -24px;
  padding: 0 9px 0 24px;
  overflow: visible;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0.1px solid #cdcdcd;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8f8e8e;
  }
}

.info-border {
  border-color: $border-color;
}

.duplicate-job__warning-msg,
.duplicate-job-cancel-btn-icon {
  color: $error;
}
.w-60px {
  width: 9ch;
}

#addJobButton {
  border: 1px solid #fff;
}
</style>
