import moment from 'moment'
import { extend, setInteractionMode } from 'vee-validate'
import i18n from '@Shared/i18n'
import {
  getBillableIndicatorCode,
  filterRestrictedJobsNotInRange,
  checkRestriction,
  groupLocations,
  formatDateFrontend,
  getTimesheets,
} from '@Shared/filters'
import {
  clientMicroServiceGet,
  clientMicroServicePost,
} from '@apis/clientService'
import {
  copyUserJobsFromPreviousWeek,
  getPPMorAltairTasks,
  updateUserJobFavorite,
  deleteSelfAssignedJob,
  updateAssignedJob,
  updateAssignedProjectTaskToTimesheet,
  getAvailableWorkcodesAndBillableIndicators,
} from '@/services/TimesheetService'
import { uniqBy, groupBy, isEmpty, pickBy, omit } from 'lodash'
import {
  durationValueValidation,
  isValidBasedOnRequiredFields,
  getLocationWfhSettings,
} from '@/utils/timesheetHelper'
import { getAxiosCancelTokenSource } from '@/utils/api'
import { EventBus } from '@Shared/EventBus'
import InlineToast from '@/components/inline-toast/InlineToast'

const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'

export default {
  loadValidationLogic({ state, getters }, payload) {
    const date = state.timesheetSelectedJob
      ? state.timesheetSelectedJob.date
      : payload.date
    const dayIndex = moment(date.split('T')[0]).isoWeekday() - 1
    const fractionAllowed = state.agencyData.timesheetDurationStep
    const newItem = state.timesheetDetail ? !state.timesheetDetail.id : false
    const durationKey = state.timesheetDetailFeatures.percentageOrDuration?.data
      .isPercentageAgency
      ? 'durationPercentageValue'
      : 'durationValue'

    const previousValue = state.timesheetDetail?.id
      ? state.timesheets.find(
          (timesheet) => timesheet.id === state.timesheetDetail.id
        )[durationKey]
      : 0

    setInteractionMode('aggressive')

    extend('required', {
      validate(value) {
        return {
          required: true,
          valid: ['', null, undefined].indexOf(value) === -1,
        }
      },
      message: (fieldName) => {
        let message = ''

        switch (fieldName) {
          case 'DurationField':
            message = i18n.t('timesheetUI.ValidationMessages.EnterTime')
            break
          case 'Workcode':
            message = i18n.t('timesheetUI.ValidationMessages.SelectWorkcode')
            break
          case 'Altair Task':
            message = i18n.t('timesheetUI.ValidationMessages.SelectAltairTask')
            break
          case 'Work From Home':
            message = i18n.t('timesheetUI.ValidationMessages.WorkFromHome')
            break
          default:
            message = i18n.t('timesheetUI.ValidationMessages.DefaultRequired', {
              fieldName,
            })
            break
        }

        return message
      },
      computesRequired: true,
    })

    extend('durationValidation', (value) =>
      durationValueValidation(
        value,
        state,
        getters.hoursTotalPerDay,
        newItem,
        dayIndex,
        fractionAllowed,
        true,
        state.timesheetDetailFeatures.percentageOrDuration?.data
          .isPercentageAgency,
        previousValue
      )
    )

    extend('quantityValidation', (value) => {
      if (
        isNaN(value) ||
        value.includes('.') ||
        (parseFloat(value) * 100) % (1 * 100) !== 0
      ) {
        return i18n.t(
          'timesheetUI.ValidationMessages.TimesheetNotCorrectIncrementQuantity'
        )
      }

      if (parseFloat(value) <= 0) {
        return i18n.t('timesheetUI.ValidationMessages.TimesheetQuanitytZero')
      }

      return true
    })

    extend('maxComment', (value) => {
      if (value.length > 125) {
        return i18n.t('timesheetUI.ValidationMessages.CommentLengthExceeded')
      }

      return true
    })

    extend('clarizenCustomTaskAllocationPeriod', (value) => {
      const error = checkRestriction(
        { date: state.timesheetDetail?.reportedDate },
        value,
        state.timesheetDetail?.durationValue || 0,
        false
      )?.errorType

      if (error) {
        return i18n.t(
          'timesheetUI.ValidationMessages.TimesheetEntryPPMTaskAllocationError'
        )
      }

      return true
    })
  },
  loadJobFeatures({ state, getters, dispatch }) {
    state.loadingFeatures = true
    const params = {
      ondate: state.timesheetSelectedJob.date.split('T')[0],
      userJobId: state.timesheetSelectedJob.job.id,
      altairCode: state.userData.altairCode,
      Culture: locale === 'fr' ? 'fr-FR' : 'en',
    }

    return clientMicroServiceGet(`timesheet/Jobs/available-features`, params)
      .then((r) => {
        // state.loadingFeatures = false
        state.timesheetDetailFeatures = r.data.value.features
        state.timesheetDetailFeaturesWorkCodes =
          state.timesheetDetailFeatures.workcode.data.workCodes
        state.timesheetDetailFeatures.isHistoricalJob =
          r.data.value.isHistoricalJob

        if (
          state.timesheetDetailFeatures.clarizenCustomTask &&
          state.timesheetDetailFeatures.clarizenCustomTask.required
        ) {
          if (
            state.timesheetDetailFeatures.clarizenCustomTask.data &&
            state.timesheetDetailFeatures.clarizenCustomTask.data.length > 0
          ) {
            state.timesheetDetailFeatures.clarizenCustomTask.data.forEach(
              (a) => {
                if (a.jobTitleForBilling) {
                  const found =
                    state.timesheetDetailFeatures?.workcode?.data?.workCodes.find(
                      (b) => b.value === a.jobTitleForBilling
                    )

                  if (!found && state.timesheetDetailFeatures?.workcode?.data) {
                    state.timesheetDetailFeatures.workcode.data.workCodes.push({
                      label: `${a.jobTitleForBilling} - ${a.jobTitleForBillingName}`,
                      selected: false,
                      value: a.jobTitleForBilling,
                    })
                  }
                }

                if (a.billableIndicator) {
                  const found = state.timesheetDetailBillableIndicators.find(
                    (b) =>
                      b.label?.toLowerCase() ===
                      a.billableIndicator?.toLowerCase()
                  )

                  if (!found) {
                    state.timesheetDetailBillableIndicators.push({
                      label: a.billableIndicator,
                      value: getBillableIndicatorCode(a.billableIndicator),
                      selected: false,
                    })
                  }
                }
              }
            )
          }
        }

        if (getters.isSelfAssignmentv1_4) {
          if (
            state.timesheetDetailFeatures?.selfAssignmentWithManageTasking
              ?.data &&
            state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data
              .length > 0
          ) {
            state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.sort(
              (a, b) => a.sortOrder - b.sortOrder
            )

            state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.forEach(
              (a) => {
                if (a.jobTitleForBilling) {
                  const found =
                    state.timesheetDetailFeatures?.workcode?.data?.workCodes.find(
                      (b) => b.value === a.jobTitleForBilling
                    )

                  if (!found && state.timesheetDetailFeatures?.workcode?.data) {
                    state.timesheetDetailFeatures.workcode.data.workCodes.push({
                      label: `${a.jobTitleForBilling} - ${a.jobTitleForBillingName}`,
                      selected: false,
                      value: a.jobTitleForBilling,
                    })
                  }
                }

                if (a.billableIndicator) {
                  const found = state.timesheetDetailBillableIndicators.find(
                    (b) =>
                      b.label?.toLowerCase() ===
                      a.billableIndicator?.toLowerCase()
                  )

                  if (!found) {
                    state.timesheetDetailBillableIndicators.push({
                      label: a.billableIndicator,
                      value: getBillableIndicatorCode(a.billableIndicator),
                      selected: false,
                    })
                  }
                }
              }
            )
          }
        }

        if (state.timesheetDetailFeatures.location.required) {
          state.timesheetDetailFeatures.location.data.locations =
            groupLocations(
              state.timesheetDetailFeatures.location.data.locations
            )
        }

        if (state.timesheetDetail && !state.timesheetDetail.workCode) {
          if (state.timesheetDetailFeatures.workcode.data) {
            if (
              state.timesheetDetailFeatures.workcode.data.workCodes.length === 1
            ) {
              state.timesheetDetail.workCode =
                state.timesheetDetailFeatures.workcode.data.workCodes[0].value
              state.timesheetDetail.workCodeName =
                state.timesheetDetailFeatures.workcode.data.workCodes[0].label
                  .split(' - ')
                  .slice(1)
                  .join(' - ')
            } else {
              const found =
                state.timesheetDetailFeatures.workcode.data.workCodes.find(
                  (wc) => wc.selected
                )
              state.timesheetDetail.workCode = found ? found.value : null
              state.timesheetDetail.workCodeName = found
                ? found.label.split(' - ').slice(1).join(' - ')
                : null
            }
          } else {
            state.timesheetDetail.workCode = state.userContracts.find((a) =>
              moment(a.onDate.split('T')[0]).isSame(
                state.timesheetSelectedJob.date.split('T')[0]
              )
            ).workCode
          }

          if (
            state.timesheetCellDetails.has(
              `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
            ) &&
            !state.timesheetCellDetails.get(
              `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
            ).workCode
          ) {
            state.timesheetCellDetails.set(
              `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`,
              {
                ...state.timesheetCellDetails.get(
                  `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
                ),
                workCode: state.timesheetDetail.workCode,
                workCodeName: state.timesheetDetail.workCodeName,
              }
            )
          }
          dispatch('loadBillableIndicators', state.timesheetDetail.workCode)
        } else {
          if (state.timesheetDetailFeatures.workcode.required) {
            // handle the scenario where a workcode is no longer available but there is an existing entry with a workcode no longer available
            if (
              (state.timesheetDetailFeatures.workcode.data.workCodes.length ===
                1 &&
                state.timesheetDetail &&
                state.timesheetDetailFeatures.workcode.data.workCodes[0]
                  .value !== state.timesheetDetail.workCode) ||
              (state.timesheetDetail &&
                state.timesheetDetail.jobTitleForBilling)
            ) {
              let defaultWorkcodeOption = {
                label: state.timesheetDetail.workCodeName,
                selected: true,
                value: state.timesheetDetail.workCode,
              }

              if (state.timesheetDetail.jobTitleForBilling) {
                defaultWorkcodeOption = {
                  label: `${state.timesheetDetail.jobTitleForBilling} - ${state.timesheetDetail.jobTitleForBillingName}`,
                  selected: true,
                  value: state.timesheetDetail.jobTitleForBilling,
                }
              }

              state.timesheetDetailFeatures.workcode.data.workCodes = [
                defaultWorkcodeOption,
              ]
            }
          }
        }

        if (
          state.timesheetDetailFeatures.clarizenCustomTask &&
          state.timesheetDetailFeatures.clarizenCustomTask.required
        ) {
          if (state.timesheetDetail && state.timesheetDetail.id !== null) {
            const found =
              state.timesheetDetailFeatures.clarizenCustomTask.data &&
              state.timesheetDetailFeatures.clarizenCustomTask.data.find(
                (a) =>
                  a.clarizenTaskExternalId.replace('/Task/', '') ===
                  state.timesheetDetail.clarizenTaskExternalId
              )

            if (!found) {
              state.timesheetDetail.ppmTaskGone = true
            }

            const savedPPMTask = {
              clarizenTaskName: state.timesheetDetail.clarizenTaskName,
              clarizenTaskExternalId:
                state.timesheetDetail.clarizenTaskExternalId,
            }

            if (
              !(
                state.timesheetDetail.timesheetStatus === 'Approved' &&
                !state.timesheetDetail.isSelfCorrected
              )
            ) {
              state.timesheetDetailFeatures.clarizenCustomTask.data = [
                savedPPMTask,
              ]
            }

            //state.timesheetDetailFeatures.clarizenCustomTask.data = [savedPPMTask]
            state.timesheetDetail.clarizenCustomTask = savedPPMTask
            state.timesheetDetail.disablePPMSelect = true
          }
        }
        if (getters.isSelfAssignmentv1_4) {
          if (state.timesheetDetail.id !== null) {
            const found =
              state.timesheetDetailFeatures.selfAssignmentWithManageTasking
                .data &&
              state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.find(
                (a) =>
                  a.clarizenTaskExternalId.replace('/Task/', '') ===
                  state.timesheetDetail.clarizenTaskExternalId
              )

            if (!found) {
              state.timesheetDetail.ppmTaskGone = true
            }

            const savedPPMTask = {
              clarizenTaskName: state.timesheetDetail.clarizenTaskName,
              clarizenTaskExternalId:
                state.timesheetDetail.clarizenTaskExternalId,
            }

            if (
              !(
                state.timesheetDetail.timesheetStatus === 'Approved' &&
                !state.timesheetDetail.isSelfCorrected
              )
            ) {
              state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data =
                [savedPPMTask]
            }

            //state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data = [savedPPMTask]
            state.timesheetDetail.selfAssignmentWithManageTasking = savedPPMTask
            state.timesheetDetail.disablePPMSelect = true
          }
        }
      })
      .catch((error) => {
        state.loadingFeatures = false
        console.log(error)
      })
      .finally(() => {
        state.loadingFeatures = false
      })
  },
  loadBillableIndicators({ state }, payload) {
    state.loadingBI = true
    const params = {
      ondate: state?.timesheetSelectedJob?.date.split('T')[0],
      userJobId: state?.timesheetSelectedJob?.job?.id,
      workcode: payload?.workcode ? payload?.workcode : payload,
      altairCode: state?.userData?.altairCode,
    }

    if (
      state?.timesheetSelectedJob?.job?.selfAssignmentWithManageTasking &&
      state?.timesheetSelectedJob?.job?.clarizenTaskExternalId
    ) {
      params.clarizenTaskExternalId =
        state?.timesheetSelectedJob?.job?.clarizenTaskExternalId
    }

    return clientMicroServiceGet(
      'timesheet/Jobs/available-billable-indicators',
      params
    )
      .then((r) => {
        state.timesheetDetailBillableIndicators =
          r.data.value.billableIndicators
        state.loadingBI = false
        if (
          !state.timesheetDetail.billableIndicator ||
          state.timesheetSelectedJob.job.selfAssignmentWithManageTasking ||
          state.timesheetSelectedJob.job.task
        ) {
          if (state.timesheetDetailBillableIndicators.length === 1) {
            state.timesheetDetail.billableIndicator =
              state.timesheetDetailBillableIndicators[0]
          } else {
            state.timesheetDetail.billableIndicator =
              state.timesheetDetailBillableIndicators.find(
                (wc) => wc.selected
              ).label
          }
        }

        if (
          state.timesheetCellDetails.has(
            `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
          ) &&
          !state.timesheetCellDetails.get(
            `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
          ).billableIndicator
        ) {
          state.timesheetCellDetails.set(
            `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`,
            {
              ...state.timesheetCellDetails.get(
                `${state.timesheetDetail.userJobId}--${state.timesheetDetail.reportedDate}`
              ),
              billableIndicator: state.timesheetDetail.billableIndicator,
            }
          )
        }
      })
      .catch((error) => {
        state.loadingBI = false
        console.log(error)
      })
  },
  loadUserData({ state, commit }, payload) {
    return clientMicroServiceGet(
      `timesheet/Users/altaircode/${
        payload || state.altairCodeOBO || state.altairCode
      }`
    ).then((r) => {
      state.userData = r.data.value

      commit(
        'setMinDateForTimesheetNavigation',
        r.data.value.showTimesheetsFrom.split('T')[0]
      )
    })
  },
  loadCalendarData({ state, getters, commit }, payload) {
    if (['navigation', 'initialLoad'].includes(payload)) {
      if (state.apiRequestCancelTokenSources.loadCalendarData) {
        state.apiRequestCancelTokenSources.loadCalendarData.cancel('Cancelled')
        state.apiRequestCancelTokenSources.loadCalendarData = null
      }

      const cancelTokenSource = getAxiosCancelTokenSource()

      const options = {
        cancelToken: cancelTokenSource.token,
      }

      state.apiRequestCancelTokenSources.loadCalendarData = cancelTokenSource

      const params = {
        fromDate: getters.selectedDate.startOfWeek,
        toDate: getters.selectedDate.endOfWeek,
      }
      state.inititalAgencyCode =
        state.firstAvailableContract?.agencyCode ?? state.inititalAgencyCode

      return clientMicroServiceGet(
        `timesheet/Users/altaircode/${
          state.altairCodeOBO || state.altairCode
        }/timesheet-contract`,
        params,
        options
      )
        .then((r) => {
          const data = r.data.value.map((a) => {
            const d = moment(a.date)

            a.dayName = d.format('ddd')
            a.dayNameLong = d.locale(locale).format('dddd')
            a.localeDayName = d.locale(locale).format('ddd')
            a.day = d.format('DD')
            return a
          })

          state.calendar = data

          state.userContracts = Object.keys(r.data.value).map(
            (a) => r.data.value[a]
          )

          const isAgencyAlreadyFound = {}

          const isWeekContract = state.userContracts
            .filter((a) => {
              const isContractValid = a.agencyCode && a.isRequiredByUserContract

              if (isContractValid && isAgencyAlreadyFound[a.agencyCode]) {
                return false
              }

              if (isContractValid && !isAgencyAlreadyFound[a.agencyCode]) {
                isAgencyAlreadyFound[a.agencyCode] = true
                return true
              }
            })
            .at(-1)

          if (isWeekContract) {
            state.firstAvailableContract = isWeekContract
          } else {
            state.firstAvailableContract = state.userContracts.find(
              (a) => a.agencyCode
            )
          }

          commit('storeAbsenceTracker/setTimesheetDays', data, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    return true
  },
  loadAgencyData({ state, getters, commit }) {
    if (state.apiRequestCancelTokenSources.loadAgencyData) {
      state.apiRequestCancelTokenSources.loadAgencyData.cancel('Cancelled')
      state.apiRequestCancelTokenSources.loadAgencyData = null
    }

    const cancelTokenSource = getAxiosCancelTokenSource()

    const options = {
      cancelToken: cancelTokenSource.token,
    }

    state.apiRequestCancelTokenSources.loadAgencyData = cancelTokenSource

    if (getters.hasSomeContract) {
      const agencyCodes = []

      state.userContracts.forEach((a) => {
        if (a.agencyCode && !agencyCodes.includes(a.agencyCode)) {
          agencyCodes.push(a.agencyCode)
        }
      })

      commit('clearAgenciesData')

      const agencyCodePromises = agencyCodes.map((agencyCode, index) => {
        return clientMicroServiceGet(
          `timesheet/Agency/rule/${agencyCode}`,
          {
            fromDate: getters.selectedDate.startOfWeek,
            toDate: getters.selectedDate.endOfWeek,
          },
          options
        )
          .then((r) => {
            commit('setAgenciesData', {
              [agencyCode]: r.data.value,
            })

            if (index !== agencyCodes.length - 1) {
              return
            }

            state.agencyData = r.data.value
            window.AltairUserContract = state.agencyData
            state.allowCopyRestOfWeek =
              state.agencyData.allowCopyRestOfWeek || false
          })
          .catch((error) => {
            console.log(error)
          })
      })

      return Promise.all(agencyCodePromises)
    }
  },
  loadContractData({ state, getters }) {
    const params = {
      fromDate: getters.selectedDate.startOfWeek,
      toDate: getters.selectedDate.endOfWeek,
    }

    return clientMicroServiceGet(
      `timesheet/Users/altaircode/${state.userData.altairCode}/contracts`,
      params
    ).then((r) => {
      state.userContracts = Object.keys(r.data.value).map(
        (a) => r.data.value[a]
      )
      state.firstAvailableContract = state.userContracts.find((a) => a)
    })
  },
  loadJobs({ state, commit, getters }, individualDispatch = false) {
    individualDispatch && commit('setLoading', true)

    if (state.apiRequestCancelTokenSources.loadJobs) {
      state.apiRequestCancelTokenSources.loadJobs.cancel('Cancelled')
      state.apiRequestCancelTokenSources.loadJobs = null
    }

    const cancelTokenSource = getAxiosCancelTokenSource()

    const options = {
      cancelToken: cancelTokenSource.token,
    }

    state.apiRequestCancelTokenSources.loadJobs = cancelTokenSource

    const params = {
      altairCode: state.userData.altairCode,
      Week: getters.selectedDate.weekOfYear,
      Year: getters.selectedDate.year,
      ver: 2,
    }

    return clientMicroServiceGet(
      `timesheet/Jobs/search-user-jobs`,
      params,
      options
    )
      .then((r) => {
        commit('setUnfilteredJobs', r.data.value)
        // const nonSelfAssignmentJobs = r.data.value.filter(
        //   (a) => !a.isClarizenAssignedProject
        // )
        // const groupedBycPid = groupBy(
        //   r.data.value.filter((a) => a.isClarizenAssignedProject),
        //   (a) => a.clarizenProjectId
        // )
        // const fusedAllocationDates = []

        // for (const key in groupedBycPid) {
        //   if (Object.hasOwnProperty.call(groupedBycPid, key)) {
        //     const found = groupedBycPid[key].find((a) => a.allocationStartDate)

        //     if (found) {
        //       groupedBycPid[key].forEach((a) => {
        //         a.allocationStartDate = found.allocationStartDate
        //         a.allocationEndDate = found.allocationEndDate
        //       })
        //     }

        //     fusedAllocationDates.push(...groupedBycPid[key])
        //   }
        // }

        // state.timesheetJobsOg = filterRestrictedJobsNotInRange(
        //   JSON.parse(
        //     JSON.stringify([...fusedAllocationDates, ...nonSelfAssignmentJobs])
        //   ),
        //   state.calendar
        // )

        // const deDupJobs = uniqBy(
        //   fusedAllocationDates,
        //   (a) => a.clarizenProjectId.toString() + a.isClarizenTask.toString()
        // )
        // state.deDupTimesheetJobsOg = filterRestrictedJobsNotInRange(
        //   [...nonSelfAssignmentJobs, ...deDupJobs],
        //   state.calendar
        // )
        // commit(
        //   'setTimesheetJobs',
        //   filterRestrictedJobsNotInRange(
        //     [...nonSelfAssignmentJobs, ...deDupJobs],
        //     state.calendar
        //   )
        // )

        // /*
        //  * RFO-1998: focus when job is added to the latest added item
        //  */
        // if (state.performRemoveButtonFocus) {
        //   dispatch('setFocusOnRemoveBtn', state.timesheetJobsOg)
        // }
        /**
         * END
         */

        // const nonSelfAssignmentJobs = r.data.value.filter(a => !a.isClarizenAssignedProject)
        // // const deDupJobs = Object.values(_.groupBy(r.data.value.filter(a => a.isClarizenAssignedProject), 'job_ID')).map(a => a[0])
        // const deDupJobs = uniqBy(r.data.value.filter(a => a.isClarizenAssignedProject), a => a.clarizenProjectId.toString() + a.isClarizenTask.toString())
        // // state.deDupTimesheetJobsOg = [...nonSelfAssignmentJobs, ...deDupJobs]
        // commit('setTimesheetJobs', [...nonSelfAssignmentJobs, ...deDupJobs])
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        individualDispatch && commit('setLoading', false)
      })
  },
  loadTimesheets({ state, commit, getters }, individualDispatch = false) {
    individualDispatch && commit('setLoading', true)

    if (state.apiRequestCancelTokenSources.loadTimesheets) {
      state.apiRequestCancelTokenSources.loadTimesheets.cancel('Cancelled')
      state.apiRequestCancelTokenSources.loadTimesheets = null
    }

    const cancelTokenSource = getAxiosCancelTokenSource()

    const options = {
      cancelToken: cancelTokenSource.token,
    }

    state.apiRequestCancelTokenSources.loadTimesheets = cancelTokenSource

    const params = {
      AltairCode: state.userData.altairCode,
      DateFrom: getters.selectedDate.startOfWeek,
      DateTo: getters.selectedDate.endOfWeek,
    }

    return clientMicroServiceGet(`timesheet/Timesheets/search`, params, options)
      .then((r) => {
        commit('setUnfilteredTimesheets', r.data.value.timesheets)
        // const applyJobRestriction = r.data.value.timesheets.map((a) => {
        //   const found = state.timesheetJobsOg.find((b) => b.id === a.userJobId)

        //   if (found) {
        //     a.allocationStartDate = found.allocationStartDate
        //     a.allocationEndDate = found.allocationEndDate
        //     a.restrictedPostEntry = checkRestriction(
        //       { date: a.reportedDate },
        //       found,
        //       a.durationValue,
        //       true
        //     )?.disabled
        //   }

        //   return a
        // })

        // commit(
        //   'setTimesheets',
        //   filterRestrictedJobsNotInRange(applyJobRestriction, state.calendar)
        // )
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        individualDispatch && commit('setLoading', false)
        // commit('setUpdateCellValuesAfterTimsheetUpdateFromDrawer')
      })
  },
  loadData({ state, dispatch, commit, getters }, payload) {
    state.loading = true
    state.loadPreviousWeekData = false

    if (payload === 'navigation') {
      commit('setNavigationLoading', true)
    }

    if (state.navigateToDate && state.initialLoad) {
      commit('setSelectedDate', state.navigateToDate)
    }

    dispatch('loadCalendarData', payload).then(() => {
      let tasks = []

      switch (payload) {
        case 'timesheets':
          tasks = ['loadTimesheets']
          break

        case 'jobs':
          tasks = ['loadJobs']
          break

        case 'jobs-timesheets':
          tasks = ['loadJobs', 'loadTimesheets']
          break
        case 'navigation':
          tasks = [
            'loadJobs',
            // 'loadContractData',
            'loadTimesheets',
            'loadAgencyData',
            'loadLocations',
            'fetchUserSettings',
          ]
          break
        case 'initialLoad':
          tasks = [
            'loadUserData',
            // 'loadContractData',
            'loadJobs',
            'loadTimesheets',
            'loadAgencyData',
            'loadLocations',
            'fetchUserSettings',
          ]
          break
        case 'userData':
          tasks = ['loadUserData', 'loadJobs', 'loadTimesheets']
          break
        default:
          tasks = []
          break
      }

      if (getters.contractChange) {
        tasks = [
          'loadUserData',
          // 'loadContractData',
          'loadJobs',
          'loadTimesheets',
          'loadAgencyData',
          'loadLocations',
          'fetchUserSettings',
        ]
      }

      const sequentialDispatch = async () => {
        if (payload === 'initialLoad') {
          await dispatch(tasks[0])
          // await dispatch(tasks[1])

          return Promise.all(
            tasks.slice(1, tasks.length + 1).map((task) => dispatch(task))
          )
        }

        if (payload === 'navigation') {
          // await dispatch(tasks[0])

          commit('setShowAssignedJobsCountInfoBanner', true)
          return Promise.all(
            tasks.slice(0, tasks.length + 1).map((task) => dispatch(task))
          )
        }

        return Promise.all(tasks.map((task) => dispatch(task)))
      }

      sequentialDispatch().then(() => {
        if (state.savedIds.length > 0) {
          commit('refreshTimesheetExists', state.savedIds)
          commit(
            'setBeforeEdit',
            JSON.parse(JSON.stringify(state.timesheetExists))
          )
        }

        dispatch('afterLoadJob')
        dispatch('afterLoadTimesheet')

        state.loading = false
        state.savingTimesheet = false

        if (payload === 'navigation') {
          commit('setNavigationLoading', false)
        }

        let currentYear = state.currentTime.startOf('week').year()

        if (state.initialLoad) {
          state.initialLoad = false
          commit('setSelectedDate', state.navigateToDate || moment())
        }

        if (!state.agencyData.enableAbsenceModule) {
          commit('storeAbsenceTracker/clearAbsenceData', {}, { root: true })
        }

        if (getters.shouldLoadAbsenceData({ loadType: payload, currentYear })) {
          state.initialLoadYear = currentYear
          dispatch(
            'storeAbsenceTracker/loadAbsenseData',
            {
              userFullName: state.userData?.fullName,
              loginUserAltairCode: state.altairCode,
              altairCodeOBO: state.altairCodeOBO,
              year: state.currentTime.startOf('week').year(),
              leaveType: '',
              calendar: state.calendar,
            },
            { root: true }
          )
        }
      })
    })
  },
  loadLocations({ state, getters }) {
    return clientMicroServiceGet('timesheet/Jobs/get-locations', {
      agencyCode: state.firstAvailableContract.agencyCode,
      fromDate: getters.selectedDate.startOfWeek,
      toDate: getters.selectedDate.endOfWeek,
    }).then((r) => {
      state.locationList = groupLocations(r.data.value)
    })
  },
  // saveTimesheetCell({ state, dispatch }, jobId) {
  //   console.log('jobId', jobId)
  //   if (Object.hasOwn(state.isInlineErrorResolved, jobId)) {
  //     return
  //   }

  //   if (state.timesheetCellDetails.has(jobId)) {
  //     const details = state.timesheetCellDetails.get(jobId)

  //     dispatch('saveTimesheet', {
  //       jobs: [details],
  //       isInline: true,
  //       clearId: jobId,
  //     })
  //   } else {
  //     this._vm.$toast.error('Job Details not found!')
  //   }
  // },
  saveTimesheet({ state, getters, dispatch, rootGetters }, payload = {}) {
    let timesheetsToSave = []
    const isInline = payload.isInline ?? false
    const userJobId = state.timesheetDetail?.userJobId
      ? JSON.parse(JSON.stringify(state.timesheetDetail.userJobId))
      : null

    if (state.copyRestOfWeekSelected) {
      window.DISABLE_GLOBAL_ALERT = true
      const daysToReplicate = []

      const originalTimesheet = (day) => {
        return moment(day.date).isSame(
          state.timesheetDetail.reportedDate,
          'day'
        )
      }

      const agencyRules = (day) => {
        return day.agencyStartDate &&
          moment(day.agencyStartDate).isSameOrAfter(new Date(), 'day') &&
          day.agencyLeftDate
          ? moment(day.agencyLeftDate).isSameOrAfter(new Date(), 'day')
          : true
      }

      const reportingDayRules = (day) => {
        return (
          moment(day.date).isSameOrBefore(new Date(), 'day') &&
          moment(day.date).isSameOrAfter(
            state.timesheetDetail.reportedDate,
            'day'
          )
        )
      }

      const hoursRules = (i, day) => {
        const isTheMainEntryToday =
          state.timesheetDetail.id && state.timesheetDetail.dayIndex === i

        if (state.agencyData.isPercentageAgency) {
          const addEnteredValue = isTheMainEntryToday
            ? 0
            : parseFloat(state.timesheetDetail.durationPercentageValue)

          const result =
            getters.hoursTotalPerDay[i].percent + addEnteredValue <= 100

          if (!result) {
            this._vm.$toast.warning(
              `${i18n.t(
                'timesheetUI.ValidationMessages.TimesheetExceeded100'
              )} ${formatDateFrontend(day.date)}`
            )
          }

          return result
        }

        const addEnteredValue = isTheMainEntryToday
          ? 0
          : parseFloat(state.timesheetDetail.durationValue)

        const result = getters.hoursTotalPerDay[i].hours + addEnteredValue <= 24

        if (!result) {
          this._vm.$toast.warning(
            `${i18n.t(
              'timesheetUI.ValidationMessages.DailyTotalDurationExceededMessage'
            )} ${formatDateFrontend(day.date)}`
          )
        }

        return result
      }

      const checkAbsenceModule = (day) => {
        if (state.agencyData.enableAbsenceModule) {
          const foundHoliday = rootGetters[
            'storeAbsenceTracker/getAbsenceTotalByDay'
          ].find((a) =>
            moment(day.date.split('T')[0]).isSame(a.date.split('T')[0])
          )

          return !foundHoliday
        }

        if (!state.agencyData.enableAbsenceModule) {
          return !day.isHoliday
        }
      }

      const checkAllocation = (day) => {
        const foundJob = state.timesheetJobsOg.find((a) => a.id === userJobId)
        if (foundJob) {
          return !checkRestriction(day, foundJob).disabled
        }

        return true
      }

      state.calendar.forEach((day, i) => {
        if (
          day.isRequiredByUserContract &&
          !day.isWeekend &&
          reportingDayRules(day) &&
          agencyRules(day) &&
          hoursRules(i, day) &&
          checkAbsenceModule(day) &&
          checkAllocation(day)
        ) {
          const newTimesheet = JSON.parse(JSON.stringify(state.timesheetDetail))
          if (!originalTimesheet(day)) {
            newTimesheet.id = null
          }
          newTimesheet.toSave = true
          newTimesheet.dayIndex = i
          newTimesheet.reportedDate = day.date
          daysToReplicate.push(newTimesheet)

          const isSaving = {
            jobId: newTimesheet.userJobId,
            date: day.date,
            isSaving: true,
          }

          EventBus.$emit('copyRestOfWeekStarted', isSaving)
        }
      })

      timesheetsToSave = daysToReplicate
    } else {
      if (!isEmpty(payload.timesheets) && isInline) {
        timesheetsToSave = [...payload.timesheets]
      }

      if (Array.isArray(state.timesheetExists) && !isInline) {
        timesheetsToSave = state.timesheetExists.filter(
          (timesheet) => timesheet.toSave
        )
      }

      if (state.timesheetDetail && !isInline) {
        if (!state.timesheetDetail.id) {
          if (state.timesheetExists) {
            if (
              state.timesheetExists.every(
                (a) => a.id !== state.timesheetDetail.id
              )
            ) {
              timesheetsToSave.push(state.timesheetDetail)
            }
          } else {
            timesheetsToSave = [state.timesheetDetail]
          }
        }
      }
    }

    const promises = timesheetsToSave.map((a) => {
      if (timesheetsToSave.length === 1 || a.toSave) {
        if (!a.isPercentageEntry && state.timesheetDetailFeatures) {
          a.isPercentageEntry =
            state.timesheetDetailFeatures.percentageOrDuration.data.isPercentageAgency
        }

        const params = {
          id: a.id,
          userJobId: a.userJobId,
          altairCode: a.altairCode,
          managerAltairCode: a.managerAltairCode,
          assignedApproverAltairCode: a.assignedApproverAltairCode,
          dayIndex: a.dayIndex,
          reportedDate: a.reportedDate,
          isPercentageEntry: a.isPercentageEntry,
          comment: a.comment,
          location: a.location || null,
          locationCode: a.locationCode || null,
          countryCode: a.countryCode || null,
          productReference: a.productReference || null,
          productQuantity: a.productQuantity || null,
          crossCompanyRuleId: a.crossCompanyRuleId,
          workCode: a.jobTitleForBilling || a.workCode || null,
          activity: a.activity,
          billableIndicator: getBillableIndicatorCode(a.billableIndicator),
          task: a.task || null,
          isClarizenTask: a.isClarizenTask,
        }

        if (getters.isSelfAssignmentv1_4) {
          params.selfAssignmentWithManageTasking = true
        }

        if (a.location) {
          params.wfh = a.wfh
        }

        if (a.clarizenCustomTask && !a.selfAssignmentWithManageTasking) {
          const found =
            state.timesheetDetailFeatures.clarizenCustomTask.data.find(
              (b) =>
                b &&
                b.clarizenTaskExternalId ===
                  a.clarizenCustomTask.clarizenTaskExternalId
            )
          if (found) {
            params.clarizenTaskId = found.clarizenTaskExternalId.includes(
              '/Task/'
            )
              ? found.clarizenTaskExternalId
              : `/Task/${found.clarizenTaskExternalId}`
            params.clarizenTaskName = found.clarizenTaskName
            params.isClarizenTaskAssignedToResource =
              found.isClarizenTaskAssignedToResource

            const foundTask = state.timesheetJobsOg.find(
              (b) =>
                `/Task/${b.clarizenTaskExternalId}` ===
                a.clarizenCustomTask.clarizenTaskExternalId
            )
            // const defaultTask = state.timesheetJobsOg[0]
            params.userJobId = foundTask ? foundTask.id : a.userJobId
            params.isPerformanceUtilization =
              found.isPerformanceUtilization || false
          }
        }

        // Self assignment v1.4 code starts from here
        if (a.selfAssignmentWithManageTasking) {
          const found =
            state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.find(
              (b) =>
                b.clarizenTaskExternalId ===
                a.selfAssignmentWithManageTasking.clarizenTaskExternalId
            )

          params.clarizenTaskId = found.clarizenTaskExternalId.includes(
            '/Task/'
          )
            ? found.clarizenTaskExternalId
            : `/Task/${found.clarizenTaskExternalId}`
          params.clarizenTaskName = found.clarizenTaskName

          const foundTask = state.timesheetJobsOg.find(
            (b) =>
              `${b.clarizenTaskExternalId}` ===
              a.selfAssignmentWithManageTasking.clarizenTaskExternalId
          )
          params.userJobId = foundTask ? foundTask.id : a.userJobId
          params.isClarizenTaskAssignedToResource = foundTask ? true : false
        }

        if (a.isPercentageEntry) {
          params.durationPercentageValue = parseFloat(a.durationPercentageValue)
        } else {
          params.durationValue = parseFloat(a.durationValue)
        }

        if (
          a.timesheetStatus === 'Approved' &&
          state.agencyData.enableSelfCorrection &&
          !state.replaceDurationValue
        ) {
          params.id = null
          params.selfCorrectedID = state.timesheetDetailOld.id
          params.isSelfCorrected = true
          params.selfCorrectedHours =
            parseFloat(state.timesheetDetailOld.durationValue) * -1
          params.durationValue = 0
          params.comment = state.timesheetDetailOld.comment
          params.location = state.timesheetDetailOld.location || null
          params.locationCode = state.timesheetDetailOld.locationCode || null
          params.countryCode = state.timesheetDetailOld.countryCode || null
          params.workCode =
            state.timesheetDetailOld.jobTitleForBilling ||
            state.timesheetDetailOld.workCode ||
            null
          params.activity = state.timesheetDetailOld.activity
          params.billableIndicator = getBillableIndicatorCode(
            state.timesheetDetailOld.billableIndicator
          )
          params.task = state.timesheetDetailOld.task
        }

        if (
          state.replaceDurationValue &&
          state.agencyData.enableSelfCorrection &&
          a.timesheetStatus !== 'Rejected'
        ) {
          params.id = null
          params.durationValue = parseFloat(a.durationValue)
        }

        if (
          a.timesheetStatus === 'Rejected' &&
          state.agencyData.enableSelfCorrection &&
          a.selfCorrectedHours < 0
        ) {
          params.selfCorrectedID = ''
          params.isSelfCorrected = false
          params.selfCorrectedHours = 0
          params.durationValue = 0
          params.durationPercentageValue = 0
        }

        return clientMicroServicePost('timesheet/Timesheets/save', params, {
          isInline,
        })
      } else {
        return {
          data: {
            value: {
              id: a.id,
            },
          },
        }
      }
    })

    return dispatch('updateTimesheet', {
      ...payload,
      promises,
      jobIds: payload?.clearId,
      isInline,
      isPreferenceDialog: payload?.isPreferenceDialog,
    })
  },

  updateTimesheet({ state, commit, dispatch }, payload) {
    state.savedIds = []

    state.savingTimesheet = true
    let failResponse = []

    return Promise.allSettled(payload.promises)
      .then((response) => {
        const successResponse = response
          .filter((r) => r.status === 'fulfilled')
          .map((r) => r.value)

        failResponse = response
          .filter((r) => r.status === 'rejected')
          .map((r) => r.reason)

        const r = successResponse
        const cellDetailKeys = []
        r.forEach((a) => {
          commit('removeTimeEntryError', {
            jobId: a.data.value.userJobId,
            reportedDate: a.data.value.reportedDate,
          })

          const cellDetailsKey = `${
            a.data.value.userJobId
          }--${a.data.value.reportedDate.replace('Z', '')}`

          if (state.timesheetCellDetails.has(cellDetailsKey)) {
            state.timesheetCellDetails.set(cellDetailsKey, a.data.value)

            cellDetailKeys.push(cellDetailsKey)
          }
        })
        payload.isPreferenceDialog &&
          commit(
            'setUpdateCellStateAfterTimsheetUpdateFromLocation',
            cellDetailKeys
          )

        if (payload.isInline && r.length) {
          if (!isEmpty(state.preparingForAutoSave)) {
            if (payload.isPreferenceDialog) {
              dispatch('loadTimesheets', true).then(() => {
                dispatch('afterLoadTimesheet')
                commit(
                  'removePreparingAutoSave',
                  r.map(
                    (a) =>
                      `${a.data.value.userJobId}--${moment(
                        a.data.value.reportedDate
                      ).format('YYYY-MM-DDT00:00:00')}`
                  )
                )
              })
            } else {
              commit(
                'removePreparingAutoSave',
                r.map(
                  (a) =>
                    `${a.data.value.userJobId}--${moment(
                      a.data.value.reportedDate
                    ).format('YYYY-MM-DDT00:00:00')}`
                )
              )
            }
          }

          r?.forEach((a) => {
            const jobId = `${a.data.value.userJobId}--${moment(
              a.data.value.reportedDate
            ).format('YYYY-MM-DDT00:00:00')}`
            // state.timesheetCellDetails[jobId] = true
            // state.timesheetCellDetails.delete(jobId)
            state.isInlineErrorResolved = omit(
              state.isInlineErrorResolved,
              jobId
            )
          })

          return Promise.resolve(r[0].data.value)
        }

        if (r.length === 1) {
          state.timesheetCellDetails.set(
            `${
              r[0].data.value.userJobId
            }--${r[0].data.value.reportedDate.replace('Z', '')}`,
            r[0].data.value
          )
        }

        if (r.length && r.every((b) => b)) {
          state.savedIds = r.map((a) => a.data.value.id)

          commit('resetTableToSave')

          commit(
            'removePreparingAutoSave',
            r.map(
              (a) =>
                `${a.data.value.userJobId}--${moment(
                  a.data.value.reportedDate
                ).format('YYYY-MM-DDT00:00:00')}`
            )
          )

          !payload.isInline &&
            !payload.preventTimesheetRefresh &&
            dispatch('loadTimesheets', true).then(() => {
              dispatch('afterLoadTimesheet')
              commit('refreshTimesheetExists', state.savedIds)
              commit(
                'setBeforeEdit',
                JSON.parse(JSON.stringify(state.timesheetExists))
              )
              commit('setTimesheetDetail', {
                data: null,
                openDrawer: false,
              })
            })
        } else {
          commit('revertTimesheet', state.beforeEdit)
          commit('resetTableToSave')
        }
      })
      .catch((error) => {
        // this._vm.$toast.error(
        //   error.response?.data.message || 'Something went wrong'
        // )
        if (payload.isInline) throw error
      })
      .finally(() => {
        state.preparingForAutoSave = null
        state.savingTimesheet = false
        EventBus.$emit('copyRestOfWeekStarted', { offAll: true })

        if (failResponse.length) {
          const error = failResponse[0]
          failResponse.forEach((e) => {
            if (window.DISABLE_GLOBAL_ALERT) {
              this._vm.$toast.error({
                component: InlineToast,
                props: {
                  message: `<div>
                    <h3>Error on: ${formatDateFrontend(
                      e.response?.data.errorData[0].date
                    )}</h3>
                    <p>${e.response?.data.message || 'Something went wrong'}</p>
                  </div>`,
                },
              })
            }
            if (payload.isInline) {
              const data = JSON.parse(e.response.config.data)
              state.isInlineErrorResolved = {
                ...state.isInlineErrorResolved,
                [`${data.userJobId}--${data.reportedDate}`]: {
                  isResolved: false,
                  type: 'api',
                },
              }
              this._vm.$toast.error({
                component: InlineToast,
                props: {
                  message: e.response?.data.message || 'Something went wrong',
                },
              })
            }
          })
          if (failResponse.length === 1) throw error
        }

        window.DISABLE_GLOBAL_ALERT = false
      })
  },
  addNewTimesheet({ commit, getters, state }, { showDrawer = true }) {
    commit('setTimesheetDetail', {
      data: JSON.parse(JSON.stringify(getters.newTimesheetObj)),
      dialog: false,
      openDrawer: showDrawer,
    })

    if (state.timesheetDetailFeatures?.workcode?.data) {
      state.timesheetDetailFeatures.workcode.data.workCodes =
        state.timesheetDetailFeaturesWorkCodes
    }
    // state.timesheetDetailFeatures.workcode.data.workCodes =
    //   state.timesheetDetailFeaturesWorkCodes
  },
  loadOnBehalfOfMappings({ state, commit }) {
    return clientMicroServiceGet(
      `timesheet/Users/altaircode/${state.altairCode}/onbehalfof`
    ).then((r) => {
      commit('setOnBehalfOfMappings', r.data.value)
    })
  },
  setFocusOnRemoveBtn({ state }, payload) {
    if (payload && payload.length > 0) {
      return setTimeout(() => {
        const lastRemoveButton = `job-${payload.slice(-1)[0].job_ID}`
        const rmvBtn = document.getElementById(lastRemoveButton)

        if (rmvBtn) {
          rmvBtn.focus()
        }

        state.performRemoveButtonFocus = false
      }, 250)
    }
  },
  getAvailableFeaturesByLocation({ state, commit }) {
    const params = {
      ondate: state.timesheetSelectedJob.date.split('T')[0],
      userJobId: state.timesheetSelectedJob.job.id,
      altairCode: state.userData.altairCode,
      culture: locale === 'fr' ? 'fr-FR' : 'en',
      location: state.timesheetDetail.location,
    }

    return clientMicroServiceGet(
      `timesheet/Jobs/available-location-features`,
      params
    )
      .then((r) => {
        commit(
          'setFreelancerWorkCodes',
          r.data.value.features.workcode.data.workCodes
        )
      })
      .catch((error) => {
        console.log(error)
      })
  },

  async copyJobsFromPreviousWeek({ state, getters, commit, dispatch }) {
    try {
      commit('setLoading', true)

      const params = {
        altairCode: state.userData.altairCode,
        week: getters.selectedDate.weekOfYear,
        year: getters.selectedDate.year,
        excludePPM: false,
        ver: 2,
      }
      await copyUserJobsFromPreviousWeek(params)
      dispatch('loadData', 'jobs-timesheets')
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchPPMTasks({ state, commit, getters }, payload) {
    commit('setLoading', true)
    commit('setPPMTasksByJobId', {
      jobId: payload.jobId,
      value: {
        data: [],
        isDataLoading: true,
        isDataLoaded:
          state.PPMTasksByJobId[payload.jobId]?.isDataLoaded ?? false,
      },
    })

    const params = {
      jobId: payload.jobId,
      agencyCode: state.firstAvailableContract.agencyCode,
      taskType: 0,
      startDate: getters.selectedDate.startOfWeek,
      endDate: getters.selectedDate.endOfWeek,
    }
    try {
      const PPMTasksList = await getPPMorAltairTasks(params)

      commit('setPPMTasksByJobId', {
        jobId: payload.jobId,
        value: {
          data: PPMTasksList.data.value,
          isDataLoading: false,
          isDataLoaded: true,
        },
      })
    } catch (error) {
      console.log(error)
      commit('setPPMTasksByJobId', {
        jobId: payload.jobId,
        value: {
          data: [],
          isDataLoading: false,
          isDataLoaded: false,
        },
      })
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchAltairTasks({ state, commit, getters }, payload) {
    commit('setLoading', true)
    commit('setAltairTasksByJobId', {
      jobId: payload.jobId,
      value: {
        data: [],
        isDataLoading: true,
        isDataLoaded:
          state.altairTasksByJobId[payload.jobId]?.isDataLoaded ?? false,
      },
    })

    const params = {
      jobId: payload.jobId,
      agencyCode: state.firstAvailableContract.agencyCode,
      taskType: 1,
      startDate: getters.selectedDate.startOfWeek,
      endDate: getters.selectedDate.endOfWeek,
    }
    try {
      const altairTasksList = await getPPMorAltairTasks(params)

      commit('setAltairTasksByJobId', {
        jobId: payload.jobId,
        value: {
          data: altairTasksList.data.value,
          isDataLoading: false,
          isDataLoaded: true,
        },
      })
    } catch (error) {
      console.log(error)
      commit('setAltairTasksByJobId', {
        jobId: payload.jobId,
        value: {
          data: [],
          isDataLoading: false,
          isDataLoaded: false,
        },
      })
    } finally {
      commit('setLoading', false)
    }
  },

  saveUserSettings(
    { state, dispatch, getters, commit },
    { settings: payload, isPreferenceDialog = false }
  ) {
    state.savingSettings = true
    commit('setLoading', true)

    return clientMicroServicePost(
      'timesheet/Jobs/save-userjob-settings',
      payload
    )
      .then(() => {
        state.pendingUserLocationSettings &&
          commit('setPendingUserLocationSettings', null)

        state.pendingUserWFHSettings &&
          commit('setPendingUserWFHSettings', null)

        return dispatch('fetchUserSettings')
      })
      .then((r) => {
        commit('setLoading', false)
        const currentWeekInlineErrors = pickBy(
          state.isInlineErrorResolved,
          (_, key) => {
            const date = key.split('--')[1]
            const week = moment(date).week()
            return week === getters.selectedDate.weekOfYear
          }
        )

        Object.keys(currentWeekInlineErrors).forEach((e) => {
          const date = e.split('--')[1]
          const isNewEntry = !state.timesheetCellDetails.get(e)?.id

          if (isNewEntry) {
            const locationWfhDetails = getLocationWfhSettings(
              r.data.value,
              date,
              state.locationList
            )
            // commit('setTimesheetDetail', {
            //   data: {
            //     ...state.timesheetDetail,
            //     ...state.timesheetCellDetails.get(e),
            //     location: locationWfhDetails.location ?? '',
            //     locationCode: locationWfhDetails.locationCode ?? '',
            //     wfh: locationWfhDetails.wfh ?? '',
            //   },
            //   openDrawer: state.isTimeEntryDrawerOpen,
            // })
            commit('timesheetCellDetailsUpdateUserSettings', {
              jobId: e,
              locationWfhDetails,
            })
          }
        })

        //update cell details with latest setting incase they were focused previously to avoid
        //stale timesheetDetail
        for (let [key] of state.timesheetCellDetails) {
          const date = key.split('--')[1]
          const week = moment(date).week()
          const isNewEntry = !state.timesheetCellDetails.get(key)?.id
          if (
            week === getters.selectedDate.weekOfYear &&
            isNewEntry &&
            // avoid setting values again if they were set previously due to inline error
            !Object.keys(currentWeekInlineErrors).includes(key)
          ) {
            // commit('setTimesheetDetail', {
            //   data: {
            //     ...state.timesheetDetail,
            //     ...state.timesheetCellDetails.get(key),
            //     location: locationWfhDetails.location ?? '',
            //     locationCode: locationWfhDetails.locationCode ?? '',
            //     wfh: locationWfhDetails.wfh ?? '',
            //   },
            //   openDrawer: state.isTimeEntryDrawerOpen,
            // })
            commit('timesheetCellDetailsUpdateUserSettings', {
              jobId: key,
              locationWfhDetails: getLocationWfhSettings(
                r.data.value,
                date,
                state.locationList
              ),
            })
          }
        }

        if (
          state.isTimeEntryDrawerOpen &&
          state.timesheetDetail &&
          !state.timesheetDetail.id
        ) {
          const key = `${state.timesheetSelectedJob.job.id}--${state.timesheetSelectedJob.date}`
          const sheets = getTimesheets(
            state.timesheets,
            state.timesheetDetail.userJobId,
            state.timesheetDetail.reportedDate
          )
          const data = sheets.length
            ? {
                ...state.timesheetDetail,
                ...getLocationWfhSettings(
                  r.data.value,
                  state.timesheetDetail.reportedDate,
                  state.locationList
                ),
              }
            : state.timesheetCellDetails.get(key)

          commit('setTimesheetDetail', {
            data,
            openDrawer: state.isTimeEntryDrawerOpen,
          })
        }

        isPreferenceDialog &&
          dispatch('handlePendingInlineValidationAndSave', isPreferenceDialog)
      })
      .catch((e) => console.error(e))
      .finally(() => {
        state.savingSettings = false
        state.preferenceDialogOpen = false
      })
  },

  fetchUserSettings({ state, getters }) {
    const params = {
      altairCode: state.userData.altairCode,
      week: getters.selectedDate.weekOfYear,
      year: getters.selectedDate.year,
    }
    return clientMicroServiceGet('timesheet/Jobs/get-userjob-settings', params)
      .then((r) => {
        state.userSettings = [...r.data.value].map((e) => {
          let updatedSetting = e
          if (e.location === '') {
            updatedSetting = {
              ...updatedSetting,
              location: null,
            }
          }
          if (e.wfh === '') {
            updatedSetting = {
              ...updatedSetting,
              wfh: null,
            }
          }
          return updatedSetting
        })
        return r
      })
      .catch((e) => {
        console.error(e)
      })
  },

  async handlePendingInlineValidationAndSave(
    { state, getters, dispatch, commit },
    isPreferenceDialog
  ) {
    if (isEmpty(state.isInlineErrorResolved)) return

    const invalidInlineEntries = pickBy(
      state.isInlineErrorResolved,
      (v) => !v?.isResolved && v?.type === 'validation'
    )

    let currentWeekInvalidInlineEntries = Object.keys(
      pickBy(invalidInlineEntries, (_, key) => {
        const date = key.split('--')[1]
        const week = moment(date).week()
        return week === getters.selectedDate.weekOfYear
      })
    )

    if (state.isTimeEntryDrawerOpen) {
      currentWeekInvalidInlineEntries = currentWeekInvalidInlineEntries.filter(
        (e) => {
          return (
            e !==
            `${state.timesheetSelectedJob.job.id}--${state.timesheetSelectedJob.date}`
          )
        }
      )
    }

    let correctedTimesheets = []

    for (const entry of currentWeekInvalidInlineEntries) {
      const [jobID, date] = entry.split('--')

      const isPercentageAgency =
        getters.timesheetJobs.find((j) => j.id === jobID)
          ?.availableFeaturesBillableIndicators[0]?.availablefeatures?.features
          ?.percentageOrDuration?.data?.isPercentageAgency ?? false

      const detail = state.timesheetCellDetails.get(entry)
      const isDurationValid = durationValueValidation(
        isPercentageAgency
          ? detail.durationPercentageValue
          : detail.durationValue,
        state,
        getters.hoursTotalPerDay,
        !detail.id,
        detail.dayIndex,
        state.agencyData.timesheetDurationStep,
        false,
        isPercentageAgency
      )

      if (typeof isDurationValid === 'string') {
        continue
      }

      let isValid = isValidBasedOnRequiredFields({
        job: getters.timesheetJobs.find((j) => j.id === jobID),
        date,
        detail,
        agencyData: state.agencyData,
      })
      if (isValid) {
        correctedTimesheets.push({
          jobId: entry,
          detail,
        })
      }
    }

    const fetchedFeatures = new Map()

    if (!isEmpty(correctedTimesheets)) {
      let features = []
      for (const timesheet of correctedTimesheets) {
        commit('setPreparingAutoSave', timesheet.jobId)

        const startDate = moment(getters.selectedDate.startOfWeek).isBefore(
          moment(state.firstAvailableContract.agencyStartDate)
        )
          ? state.firstAvailableContract.agencyStartDate.split('T')[0]
          : getters.selectedDate.startOfWeek

        const param = {
          userJobId: timesheet.jobId.split('--')[0],
          fromDate: startDate,
          toDate: getters.selectedDate.endOfWeek,
        }

        if (fetchedFeatures.has(param.userJobId)) {
          features.push(fetchedFeatures.get(param.userJobId))
        } else {
          const featPromise = dispatch(
            'fetchAvailableWorkcodesAndBillableIndicators',
            param
          )
          features.push(featPromise)
          fetchedFeatures.set(param.userJobId, featPromise)
        }
      }

      //parallel calls to workcode and billable indicators
      const featuresList = await Promise.all(features) //.then((featuresList) => {
      const filterJobs = []
      featuresList.forEach((feature, index) => {
        const workcodeData =
          feature?.availablefeatures.features.workcode.data ?? false

        let billable = feature?.billableIndicators

        if (billable.length === 1) {
          billable = feature?.billableIndicators[0]
        } else if (billable.length > 1) {
          billable = feature?.billableIndicators.find((f) => f.selected)
        }

        correctedTimesheets[index].detail = {
          ...correctedTimesheets[index].detail,
          billableIndicator: billable,
        }

        //update workcode
        if (!correctedTimesheets[index].detail.workCode) {
          let selectedWorkcode = workcodeData?.workCodes?.find(
            (code) => code.selected
          )

          if (!selectedWorkcode && workcodeData?.workCodes.length === 1) {
            selectedWorkcode = workcodeData?.workCodes[0]
          }

          if (selectedWorkcode) {
            const updatedTimesheet = {
              ...correctedTimesheets[index].detail,
              workCode: selectedWorkcode?.value ?? '',
              workCodeName:
                selectedWorkcode?.label.split(' - ').slice(1).join(' - ') ?? '',
            }

            correctedTimesheets[index].detail = updatedTimesheet
          } else {
            filterJobs.push(correctedTimesheets[index].jobId)
          }
        }
      })

      correctedTimesheets = correctedTimesheets.filter(
        (t) => !filterJobs.includes(t.jobId)
      )

      correctedTimesheets = uniqBy(correctedTimesheets, 'jobId').filter(
        (a) => isEmpty(a?.billableIndicator) || isEmpty(a?.workcode)
      )
      // return dispatch('saveTimesheet', {
      //   isInline: true,
      //   timesheets: correctedTimesheets.map((t) => t.detail),
      //   clearId: correctedTimesheets.map((t) => t.jobId),
      // })
      // })

      await dispatch('saveTimesheet', {
        isInline: true,
        timesheets: correctedTimesheets.map((t) => t.detail),
        clearId: correctedTimesheets.map((t) => t.jobId),
        isPreferenceDialog,
      })
    }
  },

  async markJobAsFavorite({ dispatch }, jobId) {
    const payload = {
      jobId,
      isFavJob: true,
    }
    dispatch('updateFavoriteJob', payload)
  },

  async markJobAsUnfavorite({ dispatch }, jobId) {
    const payload = {
      jobId,
      isFavJob: false,
    }
    dispatch('updateFavoriteJob', payload)
  },

  async updateFavoriteJob({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)
      const params = {
        jobId: payload.jobId,
        isFavJob: payload.isFavJob,
      }
      await updateUserJobFavorite(params)
      dispatch('updateFavoriteJobLocally', payload)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },

  updateFavoriteJobLocally({ state, commit }, payload) {
    {
      const updatedJobs = state.timesheetJobsOg.map((job) => {
        if (job.id === payload.jobId) {
          job.isFavJob_V2 = payload.isFavJob
        }
        return job
      })

      state.timesheetJobsOg = updatedJobs
    }

    {
      const updatedJobs = state.unfilteredJobs.map((job) => {
        if (job.id === payload.jobId) {
          job.isFavJob_V2 = payload.isFavJob
        }
        return job
      })

      commit('setUnfilteredJobs', updatedJobs)
    }
  },

  async addAssignedProjectTaskToTimesheet({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)

      const addTaskPayload = {
        userJobId: payload.job.id,
        isJobVisible: true,
      }

      if (payload.PPMTaskId) {
        addTaskPayload.isPPMTask = true
        addTaskPayload.ppmTaskId = payload.PPMTaskId
        addTaskPayload.clarizenTaskName = payload.PPMTaskName
      }

      if (payload.altairTaskId) {
        addTaskPayload.isAltairTask = true
        addTaskPayload.altairTaskId = payload.altairTaskId
        addTaskPayload.altairTaskName = payload.altairTaskName
      }

      const response = await updateAssignedProjectTaskToTimesheet(
        addTaskPayload
      )

      payload.job = response.data.value
      payload.mode = 'add'
      payload.isJobVisible = true
      dispatch('updateAssignedJobLocally', payload)
      dispatch('loadAndProcessJobs')

      // await dispatch('loadJobs', true)
      // dispatch('afterLoadJob')
      return response
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },

  async addAssignedTaskToTimesheet({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)
      const params = {
        jobId: payload.job.id,
        isJobVisible: true,
      }

      await updateAssignedJob(params)

      params.job = payload.job
      dispatch('updateAssignedJobLocally', params)
      // dispatch('loadJobs', true)
      commit('setPerformRemoveButtonFocus', true)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },

  async removeAssignedJob({ commit, dispatch }, job) {
    try {
      commit('setLoading', true)
      const params = {
        jobId: job.id,
        isJobVisible: false,
      }

      await updateAssignedJob(params)

      params.job = job
      params.mode = 'remove'
      dispatch('updateAssignedJobLocally', params)
    } catch (error) {
      console.log(error)
    } finally {
      commit('setLoading', false)
    }
  },

  updateAssignedJobLocally({ state, commit }, payload) {
    if (!payload.job.isClarizenAssignedProject) {
      {
        const updatedJobs = state.timesheetJobsOg.map((job) => {
          if (job.id === payload.jobId) {
            job.isJobVisible_V2 = payload.isJobVisible
          }
          return job
        })

        state.timesheetJobsOg = updatedJobs
      }

      {
        const updatedJobs = state.unfilteredJobs.map((job) => {
          if (job.id === payload.jobId) {
            job.isJobVisible_V2 = payload.isJobVisible
          }
          return job
        })

        commit('setUnfilteredJobs', updatedJobs)
      }
      return
    }

    if (payload.mode === 'add' && payload.job.isClarizenAssignedProject) {
      let isJobFound = false
      const updatedJobs = state.timesheetJobsOg.map((job) => {
        if (job.id === payload.job.id) {
          job.isJobVisible_V2 = payload.isJobVisible
          isJobFound = true
        }
        return job
      })

      if (isJobFound) {
        state.timesheetJobsOg = updatedJobs
      } else {
        state.timesheetJobsOg = [...state.timesheetJobsOg, payload.job]
      }

      return
    }

    if (payload.mode === 'remove' && payload.job.isClarizenAssignedProject) {
      state.timesheetJobsOg = state.timesheetJobsOg.filter((job) => {
        return job.id !== payload.jobId
      })

      commit(
        'setUnfilteredJobs',
        state.unfilteredJobs.filter((job) => {
          return job.id !== payload.jobId
        })
      )

      return
    }
  },

  async deleteUserJob({ state, commit, dispatch, getters }, job) {
    try {
      commit('setLoading', true)
      const payload = {
        jobId: job.id,
        altairCode: state.userData.altairCode,
        DateFrom: getters.selectedDate.startOfWeek,
        DateTo: getters.selectedDate.endOfWeek,
      }
      await deleteSelfAssignedJob(payload)

      dispatch('deleteUserJobLocally', payload)
      commit('setPerformRemoveButtonFocus', true)
    } catch (error) {
      console.log(error)
      commit('setTimesheetDeleteError', { dialog: true, data: job })
    } finally {
      commit('setLoading', false)
    }
  },

  deleteUserJobLocally({ state, commit }, payload) {
    const updatedJobs = state.timesheetJobsOg.filter((job) => {
      return job.id !== payload.jobId
    })
    state.timesheetJobsOg = updatedJobs

    commit(
      'setUnfilteredJobs',
      state.unfilteredJobs.filter((job) => {
        return job.id !== payload.jobId
      })
    )
  },

  syncUserSettingsWithForm({ state, getters }) {
    if (
      !state.timesheetDetail ||
      'timesheetStatus' in state?.timesheetDetail ||
      !state.isTimeEntryDrawerOpen
    )
      return

    const selectedLocationByDayObj = state.locationList
      .filter((l) => !l?.header)
      .find((l) => l.value === getters.getUserSettingByDay?.location)

    const selectedLocationFullweek = state.locationList
      .filter((l) => !l?.header)
      .find((l) => l.value === getters.getUserSettingByWeek?.location)

    const tranformedWfhByDay =
      getters.getUserSettingByDay?.wfh === 'Yes'
        ? true
        : getters.getUserSettingByDay?.wfh === 'No'
        ? false
        : getters.getUserSettingByDay?.wfh

    const tranformedWfhByWeek =
      getters.getUserSettingByWeek?.wfh === 'Yes'
        ? true
        : getters.getUserSettingByWeek?.wfh === 'No'
        ? false
        : getters.getUserSettingByWeek?.wfh

    state.timesheetDetail = {
      ...state.timesheetDetail,
      wfh:
        state.timesheetDetail?.wfh ??
        tranformedWfhByDay ??
        tranformedWfhByWeek ??
        '',
      location:
        state.timesheetDetail?.location ??
        selectedLocationByDayObj?.label ??
        selectedLocationFullweek?.label ??
        '',
      locationCode:
        state.timesheetDetail?.locationCode ??
        selectedLocationByDayObj?.value ??
        selectedLocationFullweek?.value ??
        '',
    }
  },

  async fetchAvailableWorkcodesAndBillableIndicators(
    { state },
    { userJobId, fromDate, toDate }
  ) {
    try {
      const params = {
        altairCode: state.userData.altairCode,
        userJobId,
        fromDate,
        toDate,
      }

      const response = await getAvailableWorkcodesAndBillableIndicators(params)
      const a = response.data.value?.[0]
      return a
    } catch (error) {
      console.log(error)
    }
  },

  loadWorkcodeandBillableIndicators({ state, getters, dispatch }) {
    if (
      state.timesheetDetailFeatures.clarizenCustomTask &&
      state.timesheetDetailFeatures.clarizenCustomTask.required
    ) {
      if (
        state.timesheetDetailFeatures.clarizenCustomTask.data &&
        state.timesheetDetailFeatures.clarizenCustomTask.data.length > 0
      ) {
        state.timesheetDetailFeatures.clarizenCustomTask.data.forEach((a) => {
          if (a.jobTitleForBilling) {
            const found =
              state.timesheetDetailFeatures?.workcode?.data?.workCodes.find(
                (b) => b.value === a.jobTitleForBilling
              )

            if (!found && state.timesheetDetailFeatures?.workcode?.data) {
              state.timesheetDetailFeatures.workcode.data.workCodes.push({
                label: `${a.jobTitleForBilling} - ${a.jobTitleForBillingName}`,
                selected: false,
                value: a.jobTitleForBilling,
              })
            }
          }

          if (a.billableIndicator) {
            const found = state.timesheetDetailBillableIndicators.find(
              (b) =>
                b.label?.toLowerCase() === a.billableIndicator?.toLowerCase()
            )

            if (!found) {
              state.timesheetDetailBillableIndicators.push({
                label: a.billableIndicator,
                value: getBillableIndicatorCode(a.billableIndicator),
                selected: false,
              })
            }
          }
        })
      }
    }

    if (getters.isSelfAssignmentv1_4) {
      if (
        state.timesheetDetailFeatures?.selfAssignmentWithManageTasking?.data &&
        state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data
          .length > 0
      ) {
        state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.sort(
          (a, b) => a.sortOrder - b.sortOrder
        )

        state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.forEach(
          (a) => {
            if (a.jobTitleForBilling) {
              const found =
                state.timesheetDetailFeatures?.workcode?.data?.workCodes.find(
                  (b) => b.value === a.jobTitleForBilling
                )

              if (!found && state.timesheetDetailFeatures?.workcode?.data) {
                state.timesheetDetailFeatures.workcode.data.workCodes.push({
                  label: `${a.jobTitleForBilling} - ${a.jobTitleForBillingName}`,
                  selected: false,
                  value: a.jobTitleForBilling,
                })
              }
            }

            if (a.billableIndicator) {
              const found = state.timesheetDetailBillableIndicators.find(
                (b) =>
                  b.label?.toLowerCase() === a.billableIndicator?.toLowerCase()
              )

              if (!found) {
                state.timesheetDetailBillableIndicators.push({
                  label: a.billableIndicator,
                  value: getBillableIndicatorCode(a.billableIndicator),
                  selected: false,
                })
              }
            }
          }
        )
      }
    }

    if (state.timesheetDetail && !state.timesheetDetail.workCode) {
      if (state.timesheetDetailFeatures.workcode.data) {
        if (
          state.timesheetDetailFeatures.workcode.data.workCodes.length === 1
        ) {
          state.timesheetDetail.workCode =
            state.timesheetDetailFeatures.workcode.data.workCodes[0].value
          state.timesheetDetail.workCodeName =
            state.timesheetDetailFeatures.workcode.data.workCodes[0].label
              .split(' - ')
              .slice(1)
              .join(' - ')
        } else {
          const found =
            state.timesheetDetailFeatures.workcode.data.workCodes.find(
              (wc) => wc.selected
            )
          state.timesheetDetail.workCode = found ? found.value : null
          state.timesheetDetail.workCodeName = found
            ? found.label.split(' - ').slice(1).join(' - ')
            : null
        }
      } else {
        state.timesheetDetail.workCode = state.userContracts.find((a) =>
          moment(a.onDate.split('T')[0]).isSame(
            state.timesheetSelectedJob.date.split('T')[0]
          )
        ).workCode
      }
    } else {
      if (state.timesheetDetailFeatures.workcode.required) {
        // handle the scenario where a workcode is no longer available but there is an existing entry with a workcode no longer available
        if (
          (state.timesheetDetailFeatures.workcode.data.workCodes.length === 1 &&
            state.timesheetDetailFeatures.workcode.data.workCodes[0].value !==
              state.timesheetDetail.workCode) ||
          (state.timesheetDetail && state.timesheetDetail.jobTitleForBilling)
        ) {
          let defaultWorkcodeOption = {
            label: state.timesheetDetail.workCodeName,
            selected: true,
            value: state.timesheetDetail.workCode,
          }

          if (state.timesheetDetail.jobTitleForBilling) {
            defaultWorkcodeOption = {
              label: `${state.timesheetDetail.jobTitleForBilling} - ${state.timesheetDetail.jobTitleForBillingName}`,
              selected: true,
              value: state.timesheetDetail.jobTitleForBilling,
            }
          }

          state.timesheetDetailFeatures.workcode.data.workCodes = [
            defaultWorkcodeOption,
          ]
        }
      }
    }
    if (state.timesheetDetail.workCode)
      dispatch('loadBillableIndicators', state.timesheetDetail.workCode)

    if (
      state.timesheetDetailFeatures.clarizenCustomTask &&
      state.timesheetDetailFeatures.clarizenCustomTask.required
    ) {
      if (state.timesheetDetail.id !== null) {
        const found =
          state.timesheetDetailFeatures.clarizenCustomTask.data &&
          state.timesheetDetailFeatures.clarizenCustomTask.data.find(
            (a) =>
              a.clarizenTaskExternalId.replace('/Task/', '') ===
              state.timesheetDetail.clarizenTaskExternalId
          )

        if (!found) {
          state.timesheetDetail.ppmTaskGone = true
        }

        const savedPPMTask = {
          clarizenTaskName: state.timesheetDetail.clarizenTaskName,
          clarizenTaskExternalId: state.timesheetDetail.clarizenTaskExternalId,
        }

        if (
          !(
            state.timesheetDetail.timesheetStatus === 'Approved' &&
            !state.timesheetDetail.isSelfCorrected
          )
        ) {
          state.timesheetDetailFeatures.clarizenCustomTask.data = [savedPPMTask]
        }

        //state.timesheetDetailFeatures.clarizenCustomTask.data = [savedPPMTask]
        state.timesheetDetail.clarizenCustomTask = savedPPMTask
        state.timesheetDetail.disablePPMSelect = true
      }
    }
    if (getters.isSelfAssignmentv1_4) {
      if (state.timesheetDetail.id !== null) {
        const found =
          state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data &&
          state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data.find(
            (a) =>
              a.clarizenTaskExternalId.replace('/Task/', '') ===
              state.timesheetDetail.clarizenTaskExternalId
          )

        if (!found) {
          state.timesheetDetail.ppmTaskGone = true
        }

        const savedPPMTask = {
          clarizenTaskName: state.timesheetDetail.clarizenTaskName,
          clarizenTaskExternalId: state.timesheetDetail.clarizenTaskExternalId,
        }

        if (
          !(
            state.timesheetDetail.timesheetStatus === 'Approved' &&
            !state.timesheetDetail.isSelfCorrected
          )
        ) {
          state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data = [
            savedPPMTask,
          ]
        }

        //state.timesheetDetailFeatures.selfAssignmentWithManageTasking.data = [savedPPMTask]
        state.timesheetDetail.selfAssignmentWithManageTasking = savedPPMTask
        state.timesheetDetail.disablePPMSelect = true
      }
    }
  },

  async loadAndProcessJobs({ dispatch }) {
    await dispatch('loadJobs', true)
    await dispatch('afterLoadJob')
    dispatch('addOutsideAllocationJobInJobList')
  },

  afterLoadJob({ state, dispatch }) {
    const nonSelfAssignmentJobs = state.unfilteredJobs.filter(
      (a) => !a.isClarizenAssignedProject
    )
    const groupedBycPid = groupBy(
      state.unfilteredJobs.filter((a) => a.isClarizenAssignedProject),
      (a) => a.clarizenProjectId
    )
    const fusedAllocationDates = []

    for (const key in groupedBycPid) {
      if (Object.hasOwnProperty.call(groupedBycPid, key)) {
        const found = groupedBycPid[key].find((a) => a.allocationStartDate)

        if (found) {
          groupedBycPid[key].forEach((a) => {
            a.allocationStartDate = found.allocationStartDate
            a.allocationEndDate = found.allocationEndDate
          })
        }

        fusedAllocationDates.push(...groupedBycPid[key])
      }
    }

    const deDupJobs = uniqBy(
      fusedAllocationDates,
      (a) =>
        a.clarizenProjectId.toString() +
        a.isClarizenTask.toString() +
        a.clarizenTaskExternalId +
        a.task
    )

    state.deDupTimesheetJobsOg = filterRestrictedJobsNotInRange(
      [...nonSelfAssignmentJobs, ...deDupJobs],
      state.calendar
    )
    state.timesheetJobsOg = JSON.parse(
      JSON.stringify([...nonSelfAssignmentJobs, ...deDupJobs])
    )
    /*
     * RFO-1998: focus when job is added to the latest added item
     */
    if (state.performRemoveButtonFocus) {
      dispatch('setFocusOnRemoveBtn', state.timesheetJobsOg)
    }
  },
  afterLoadTimesheet({ state, commit, dispatch }) {
    const applyJobRestriction = state.unfilteredTimesheets.map((a) => {
      const found = state.timesheetJobsOg.find((b) => b.id === a.userJobId)

      if (found) {
        a.allocationStartDate = found.allocationStartDate
        a.allocationEndDate = found.allocationEndDate
        a.restrictedPostEntry = checkRestriction(
          { date: a.reportedDate },
          found,
          a.durationValue,
          true
        )?.disabled
      }
      return a
    })
    commit(
      'setTimesheets',
      filterRestrictedJobsNotInRange(applyJobRestriction, state.calendar)
    )
    dispatch('addOutsideAllocationJobInJobList')
    commit('setUpdateCellValuesAfterTimsheetUpdateFromDrawer')
  },
  // Adds jobs from timesheets that are not already in the job list and have a duration greater than 0.
  addOutsideAllocationJobInJobList({ state, commit }) {
    // Update the timesheetJobsOg state by filtering out restricted jobs that are not within the calendar range
    commit(
      'setTimesheetJobsOg',
      filterRestrictedJobsNotInRange(
        JSON.parse(JSON.stringify(state.timesheetJobsOg)),
        state.calendar
      )
    )

    state.timesheets.forEach((timesheet) => {
      const found = state.timesheetJobsOg.find(
        (job) => timesheet.job_ID == job.job_ID && timesheet.durationValue !== 0
      )

      if (!found) {
        const job = state.unfilteredJobs.filter(
          (job) => timesheet.job_ID == job.job_ID
        )
        if (job) {
          commit('setTimesheetJobsOg', [...state.timesheetJobsOg, ...job])
        }
      }
    })
  },
}
