import moment from 'moment'
import {
  formatDateFrontend,
  percisionRound,
  getTimesheetsDuplicate,
  getTimesheets,
  GLOBAL_FILTERS,
} from '@Shared/filters'

import i18n from '@Shared/i18n'
import { isArray, isEmpty, uniqBy } from 'lodash'
import { sortJobs } from '@/utils/jobHelper'
import { filterItemsByKeyword } from '@/utils/timesheetHelper'

export default {
  selectedDate: (state) => {
    const startOfWeek = state.currentTime.clone().startOf('isoWeek')
    const endOfWeek = startOfWeek.clone().add(6, 'days')
    return {
      weekOfYear: state.currentTime.isoWeek(),
      year: endOfWeek.year(),
      startOfWeek: startOfWeek.format('YYYY-MM-DD'),
      endOfWeek: endOfWeek.format('YYYY-MM-DD'),
      startOfWeekFrontend: formatDateFrontend(startOfWeek),
      endOfWeekFrontend: formatDateFrontend(endOfWeek),
      dayIndex: state.currentTime.isoWeekday() - 1,
      startOfWeekLong: startOfWeek.format('dddd, DD MMM'),
      endOfWeekLong: endOfWeek.format('dddd, DD MMM'),
    }
  },
  selectedWeekRangeText: (state) => {
    return `${state.currentTime
      .startOf('week')
      .format('MMM D')} - ${state.currentTime.endOf('week').format('D, YYYY')}`
  },
  getDaysInWeek: (state) => {
    const startDate = state.currentTime.startOf('week')
    const weekdays = []
    const TOTAL_DAYS_IN_WEEK = 7

    for (var i = 0; i < TOTAL_DAYS_IN_WEEK; i++) {
      var formattedDate = startDate.clone().add(i, 'days')

      weekdays.push({
        day: formattedDate.format('dddd'),
        formattedDate: formattedDate.format('dddd, D MMMM'),
        date: formattedDate.format('YYYY-MM-DD'),
      })
    }

    return weekdays
  },
  contractChange: (state) => {
    return state.calendar.some(
      (a) => a.agencyCode !== state.calendar[0].agencyCode
    )
  },
  newTimesheetObj: (state, getters) => {
    const locationAndWfh = getters.getLocationWfhSettings

    return {
      id: null,
      userJobId: state.timesheetSelectedJob.job.id,
      altairCode: state.timesheetSelectedJob.job.altairCode,
      managerAltairCode: state.altairCodeOBO
        ? state.altairCode
        : state.timesheetSelectedJob.managerAltairCode,
      assignedApproverAltairCode:
        state.timesheetSelectedJob.assignedApproverAltairCode,
      dayIndex:
        moment(state.timesheetSelectedJob.date.split('T')[0]).isoWeekday() - 1,
      reportedDate: state.timesheetSelectedJob.date,
      comment: '',
      location: locationAndWfh.location,
      locationCode: locationAndWfh.locationCode,
      productReference: '',
      productQuantity: '',
      workCode: '',
      activity: '',
      billableIndicator: '',
      task: state.timesheetSelectedJob.job.task ?? '',
      crossCompanyRuleId: state.timesheetSelectedJob.job.crossCompanyRuleId,
      toSave: true,
      wfh: locationAndWfh.wfh,
      brandName: state.timesheetSelectedJob.job.brandName,
      jobGroupName: state.timesheetSelectedJob.job.jobGroupName,
      clarizenTaskExternalId:
        state.timesheetSelectedJob.job.clarizenTaskExternalId ?? '',
      clarizenTaskName: state.timesheetSelectedJob.job.clarizenTaskName,
    }
  },

  hoursTotalPerDay: (state, getters, rootState, rootGetters) => {
    const hoursPercentObj = {
      percent: 0,
      hours: 0,
      overtime: 0,
      overtimeDouble: 0,
    }
    const timesheetTotalByDay = new Array(7).fill(hoursPercentObj)

    state.calendar.forEach((day, index) => {
      const foundHoliday = rootGetters[
        'storeAbsenceTracker/getAbsenceTotalByDay'
      ].filter((a) =>
        moment(day.date.split('T')[0]).isSame(a.date.split('T')[0])
      )
      if (foundHoliday.length > 0) {
        foundHoliday.forEach((fItem) => {
          if (fItem) {
            const newObj = JSON.parse(
              JSON.stringify(timesheetTotalByDay[index])
            )
            newObj.hours = percisionRound(
              newObj.hours + parseFloat(fItem.total),
              0.01
            )
            newObj.percent = percisionRound(
              (newObj.hours /
                parseFloat(state.agencyData.timesheetHoursPerDay)) *
                100,
              0.01
            )
            timesheetTotalByDay[index] = newObj
          }
        })
      }

      const timesheetsForDate = state.timesheets.filter((a) =>
        moment(a.reportedDate.split('T')[0]).isSame(day.date.split('T')[0])
      )

      timesheetsForDate.forEach((jobData) => {
        if (!(jobData.taskName && jobData.taskName.includes('OTHER'))) {
          const durationValueHour = parseFloat(jobData.durationValue) || 0
          const durationPercentageValue =
            parseFloat(jobData.durationPercentageValue) || 0

          timesheetTotalByDay[index] = {
            percent: percisionRound(
              timesheetTotalByDay[index].percent + durationPercentageValue,
              0.01
            ),
            hours: percisionRound(
              timesheetTotalByDay[index].hours + durationValueHour,
              0.01
            ),
            overtime: timesheetTotalByDay[index].overtime,
            overtimeDouble: timesheetTotalByDay[index].overtimeDouble,
          }

          if (state.overtimeCodes.overtime.includes(jobData.task)) {
            timesheetTotalByDay[index].overtime = percisionRound(
              timesheetTotalByDay[index].overtime + durationValueHour,
              0.01
            )
          }

          if (state.overtimeCodes.overtimeDouble.includes(jobData.task)) {
            timesheetTotalByDay[index].overtimeDouble = percisionRound(
              timesheetTotalByDay[index].overtimeDouble + durationValueHour,
              0.01
            )
          }
        }
      })
    })

    return timesheetTotalByDay
  },
  validForEntry: (state) => {
    if (state.timesheetExists) {
      if (state.timesheetDetailFeatures?.allowPTOWorkcode?.required) {
        return true
      }
      // return state.timesheetExists.every((a) => a.workCode || a.activity)
    }

    if (state.timesheetDetailFeatures) {
      if (state.timesheetDetailFeatures.workcode.required) {
        return state.timesheetDetailFeatures.workcode.data.workCodes.length
      }

      if (state.timesheetDetailFeatures.activity.required) {
        return state.timesheetDetailFeatures.activity.data.activities?.length
      }
    }

    return false
  },
  hasSomeContract: (state) => {
    if (isArray(state.userContracts)) {
      return state.userContracts.some((a) => a.agencyCode)
    }

    return false
  },
  isHideTimesheetWorkcode: (state) => {
    return state.timesheetDetailFeatures?.hideTimesheetWorkcode?.required
  },
  isTemporaryExternalEmployee: (state) => {
    return (
      state.timesheetDetailFeatures?.temporaryExternalEmployee?.required &&
      state.timesheetDetailFeatures?.hideTimesheetWorkcode?.required
    )
  },
  timesheetJobs: (state) => {
    const nonSelfAssignmentJobs = state.timesheetJobsOg.filter(
      (a) => !a.isClarizenAssignedProject && !a.selfAssignmentWithManageTasking
    )

    const deDupJobs = uniqBy(
      state.timesheetJobsOg.filter((a) => {
        if (
          (a.isClarizenAssignedProject || a.selfAssignmentWithManageTasking) &&
          a.clarizenTaskExternalId &&
          a.isJobVisible_V2
        ) {
          return a
        }
      }),
      (a) =>
        a.clarizenProjectId.toString() +
        a.isClarizenTask.toString() +
        a.clarizenTaskExternalId +
        a.task
    )

    const nonSelfAssignmentVisibleJobs = nonSelfAssignmentJobs.filter((a) => {
      if (!a.clarizenTaskExternalId) return true

      const isJobWithAltairTask =
        a.availableFeaturesBillableIndicators?.[0]?.availablefeatures?.features
          .task.required

      if (isJobWithAltairTask) {
        const isClarizenTaskWithAltairTaskMasterJob =
          a.clarizenTaskExternalId &&
          a.availableFeaturesBillableIndicators?.[0]?.availablefeatures
            ?.features.task.required &&
          !a.taskName

        const isHistoricalJobWithTask =
          a.availableFeaturesBillableIndicators?.[0]?.isHistoricalJob

        if (
          isClarizenTaskWithAltairTaskMasterJob &&
          isHistoricalJobWithTask &&
          a.isJobVisible_V2
        )
          return true

        if (isClarizenTaskWithAltairTaskMasterJob && !isHistoricalJobWithTask)
          return false
      }
      return a.isJobVisible_V2 || a.isJobVisible_V2 === undefined
    })

    let timesheetJobs = window._.sortBy(
      [...nonSelfAssignmentVisibleJobs, ...deDupJobs],
      ['jobGroupName']
    )

    if (state.globalSearchSelectedFilters.length > 0) {
      state.globalSearchSelectedFilters.forEach((filter) => {
        if (filter.label === GLOBAL_FILTERS.FAVOURITES) {
          timesheetJobs = timesheetJobs.filter((job) => job.isFavJob_V2)
        }

        if (filter.label === GLOBAL_FILTERS.ASSIGNED_JOB) {
          timesheetJobs = timesheetJobs.filter(
            (job) =>
              job?.isClarizenTask && !job?.selfAssignmentWithManageTasking
          )
        }

        if (filter.label === GLOBAL_FILTERS.SELF_ASSIGNED) {
          timesheetJobs = timesheetJobs.filter(
            (job) =>
              !job?.isClarizenTask ||
              (job?.isClarizenTask && job?.selfAssignmentWithManageTasking)
          )
        }

        if (filter.label === GLOBAL_FILTERS.MY_AGENCY) {
          timesheetJobs = timesheetJobs.filter(
            (job) => job.agencyCode === state.userData.agencyCode
          )
        }

        if (filter.label === GLOBAL_FILTERS.OTHER_AGENCIES) {
          timesheetJobs = timesheetJobs.filter(
            (job) => job.agencyCode !== state.userData.agencyCode
          )
        }
      })
    }

    return filterItemsByKeyword(
      timesheetJobs,
      state.searchTimesheetJobs ? state.searchTimesheetJobs.toLowerCase() : ''
    )
  },
  groupedTimesheetJobs: (state, getters) => {
    const jobs = getters.timesheetJobs

    const grouped = Object.groupBy(
      getters.timesheetJobs,
      ({ jobGroupName }) => jobGroupName
    )

    return sortJobs(grouped, jobs, state.jobsSortOrder)
  },
  doesAnyJobRequireLocation: (state, getters) => {
    return getters.timesheetJobs.some((job) =>
      job.availableFeaturesBillableIndicators?.some(
        (features) => features.availablefeatures.features.location.required
      )
    )
  },
  isSelfAssignmentv1_4: (state) => {
    return state.timesheetSelectedJob.job?.selfAssignmentWithManageTasking
  },
  isEnabledSelfCorrection: (state) => {
    return state.agencyData.enableSelfCorrection
  },
  isCorrectionValid: (state) => {
    return state.isCorrectionValid
  },
  timesheetVisibilityFilter: (state) => {
    return state.timesheetVisibilityFilter
  },
  isHiddenJobsScreen: (state) => {
    return (
      state.timesheetVisibilityFilter ===
      state.timesheetVisibilityOptionsMap.hidden
    )
  },
  isShowHideVisibilityEnabled: (state) => {
    return state.agencyData.enableShowHideJobVisiblility
  },
  isAllowPTOWorkCode: (state) => {
    return state.timesheetDetailFeatures?.allowPTOWorkcode?.required
  },
  agencyData: (state) => {
    return state.agencyData
  },
  shouldLoadAbsenceData: (state) => {
    return ({ loadType, currentYear }) => {
      const isAbsenceModuleEnabled =
        state.absenceModule &&
        (state.agencyData.enableAbsenceModule ||
          state.agencyData.absenceModuleEnabled)
      const isYearChanged = state.initialLoadYear !== currentYear
      const isAgencyChanged =
        state.inititalAgencyCode !== state.agencyData.agencyCode
      if (!isAbsenceModuleEnabled) {
        return false
      } else {
        return (
          loadType === 'initialLoad' ||
          (loadType === 'navigation' && (isYearChanged || isAgencyChanged))
        )
      }
    }
  },
  hoursTotalPerJob(state, getters) {
    /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
    state.IsPercentageByDay = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    }

    let timesheetTotalByJob = getters.groupedTimesheetJobs.sortedJobs.map(
      (item) => {
        const timesheets =
          (item.isClarizenAssignedProject && item.isClarizenTask) ||
          item.selfAssignmentWithManageTasking
            ? getTimesheetsDuplicate(
                state.timesheets,
                item.clarizenProjectId,
                false,
                true,
                item.isClarizenTask,
                item.clarizenTaskExternalId,
                item.task
              )
            : getTimesheets(state.timesheets, item.id)

        let total = 0
        let totalPercent = 0
        const daysValid = state.calendar.filter(
          (a, i) => a.isRequiredByUserContract && i <= 4
        ).length

        timesheets.forEach((timesheet) => {
          const i =
            moment(timesheet.reportedDate.split('T')[0]).isoWeekday() - 1
          state.IsPercentageByDay[i].push(timesheet.isPercentageEntry)
          total += parseFloat(timesheet.durationValue) || 0

          if (timesheet.isPercentageEntry) {
            totalPercent += parseFloat(timesheet.durationPercentageValue) || 0
          }
        })

        return {
          hours: percisionRound(total, 0.01),
          percent:
            totalPercent > 0
              ? percisionRound(totalPercent / daysValid, 0.01)
              : 0,
        }
      }
    )

    return timesheetTotalByJob
  },
  clarizenTaskCount: (state) => {
    return state.timesheetJobs.filter((item) => item.isClarizenTask).length
  },
  currentTimesheetDateRangeText(state, getters) {
    return `${moment(new Date(getters.selectedDate.startOfWeek))
      .locale(state.locale)
      .format('MMM DD')} - ${moment(getters.selectedDate.endOfWeek).format(
      'DD, YYYY'
    )}`
  },
  isTimesheetApproved: (state) => {
    if (state.timesheetDetail && state.timesheetDetail.timesheetStatus) {
      return state.timesheetDetail.timesheetStatus.toLowerCase() === 'approved'
    }
    return false
  },
  showForm(state) {
    //Note: show new form for only new timesheet object and single entry
    const showform =
      (!!state.timesheetDetail && !state.timesheetDetail.id) ||
      !state.timesheetExists ||
      state.timesheetExists.length < 2
    return showform
  },

  activeAssignedTasksCount(_, getters) {
    return getters.timesheetJobs.reduce(
      (a, b) => a + (b.isClarizenTask && !b.isClarizenAssignedProject ? 1 : 0),
      0
    )
  },

  shelvedAssignedTasksCount(_, getters) {
    return getters.shelvedJobs.reduce(
      (a, b) => a + (b.isClarizenTask && !b.isClarizenAssignedProject ? 1 : 0),
      0
    )
  },

  inactiveAssignedProjectsCount(state, getters) {
    const assignedProjectsJobIDs = []
    getters.shelvedJobs.forEach((job) => {
      if (
        job.job_ID &&
        job.isClarizenAssignedProject &&
        !job.selfAssignmentWithManageTasking
      ) {
        assignedProjectsJobIDs.push(job.job_ID)
      }
    })

    const assignedProjectsWithTasksAddedToTimesheet = []

    assignedProjectsJobIDs.forEach((job_ID) => {
      const isAnyTaskFromProjectAdded = getters.timesheetJobs.some((job) => {
        return (
          job.job_ID === job_ID &&
          job.isClarizenAssignedProject &&
          !job.selfAssignmentWithManageTasking
        )
      })

      if (isAnyTaskFromProjectAdded)
        assignedProjectsWithTasksAddedToTimesheet.push(job_ID)
    })

    return (
      assignedProjectsJobIDs.length -
      assignedProjectsWithTasksAddedToTimesheet.length
    )
  },

  unFilteredshelvedAssignedTasksCount(state) {
    return state.unFilteredShelvedJobs.reduce(
      (a, b) => a + (b.isClarizenTask && !b.isClarizenAssignedProject ? 1 : 0),
      0
    )
  },

  unFilteredInactiveAssignedProjectsCount(state, getters) {
    const assignedProjectsJobIDs = []
    state.unFilteredShelvedJobs.forEach((job) => {
      if (
        job.job_ID &&
        job.isClarizenAssignedProject &&
        !job.selfAssignmentWithManageTasking
      ) {
        assignedProjectsJobIDs.push(job.job_ID)
      }
    })

    const assignedProjectsWithTasksAddedToTimesheet = []

    assignedProjectsJobIDs.forEach((job_ID) => {
      const isAnyTaskFromProjectAdded = getters.timesheetJobs.some((job) => {
        return (
          job.job_ID === job_ID &&
          job.isClarizenAssignedProject &&
          !job.selfAssignmentWithManageTasking
        )
      })

      if (isAnyTaskFromProjectAdded)
        assignedProjectsWithTasksAddedToTimesheet.push(job_ID)
    })

    return (
      assignedProjectsJobIDs.length -
      assignedProjectsWithTasksAddedToTimesheet.length
    )
  },

  inActiveAssignedItemsCount(_, getters) {
    return (
      getters.shelvedAssignedTasksCount + getters.inactiveAssignedProjectsCount
    )
  },

  unFilteredinActiveAssignedItemsCount(_, getters) {
    return (
      getters.unFilteredInactiveAssignedProjectsCount +
      getters.unFilteredshelvedAssignedTasksCount
    )
  },

  selectedDay(state) {
    return moment(state.timesheetSelectedJob.date).format('dddd').toLowerCase()
  },

  getPreferenceLocationLabel(state) {
    const locations = state.userSettings
      .filter((item) => item.location)
      .map((item) => item.location)
    const uniqueLocations = [...new Set(locations)]

    if (uniqueLocations.length === 1) {
      return state.locationList.find((l) => l.value === uniqueLocations[0])
        ?.label
    } else if (uniqueLocations.length > 1) {
      return i18n.t(
        'timesheetUI.ManagePreference.MultipleLocations',
        window.CULTURE
      )
    } else {
      return ''
    }
  },

  getUserSettingByDay(state, getters) {
    return state.userSettings.find(
      (s) =>
        s?.date &&
        moment(s.date).format('dddd').toLowerCase() ===
          getters.selectedDay.toLowerCase()
    )
  },

  getUserSettingByWeek(state) {
    return state.userSettings.find(
      (s) => s.isLocationRestOfWeek || s.isWfhRestOfWeek
    )
  },

  getLocationWfhSettings(state, getters) {
    const selectedLocationByDayObj = state.locationList
      .filter((l) => !l?.header)
      .find((l) => l.value === getters.getUserSettingByDay?.location)

    const selectedLocationFullweek = state.locationList
      .filter((l) => !l?.header)
      .find((l) => l.value === getters.getUserSettingByWeek?.location)

    const tranformedWfhByDay =
      getters.getUserSettingByDay?.wfh === 'Yes'
        ? true
        : getters.getUserSettingByDay?.wfh === 'No'
        ? false
        : getters.getUserSettingByDay?.wfh

    const tranformedWfhByWeek =
      getters.getUserSettingByWeek?.wfh === 'Yes'
        ? true
        : getters.getUserSettingByWeek?.wfh === 'No'
        ? false
        : getters.getUserSettingByWeek?.wfh

    return {
      location:
        selectedLocationByDayObj?.label ??
        selectedLocationFullweek?.label ??
        '',
      locationCode:
        selectedLocationByDayObj?.value ??
        selectedLocationFullweek?.value ??
        '',
      wfh: tranformedWfhByDay ?? tranformedWfhByWeek ?? '',
    }
  },

  isAnyJobPresentOnTimesheet(_, getters) {
    return getters.timesheetJobs.length > 0
  },

  isAbsenceModuleEnabled(state) {
    return state.agencyData.enableAbsenceModule
  },

  shelvedJobs(state) {
    const nonSelfAssignmentJobs = state.timesheetJobsOg.filter(
      (a) =>
        !a.isClarizenAssignedProject &&
        !a.selfAssignmentWithManageTasking &&
        a.clarizenTaskExternalId
    )

    const deDupJobs = uniqBy(
      state.timesheetJobsOg.filter((a) => {
        if (
          a.isClarizenAssignedProject &&
          !a.selfAssignmentWithManageTasking &&
          !a.clarizenTaskExternalId
        ) {
          return a
        }
      }),
      (a) => a.clarizenProjectId.toString() + a.isClarizenTask.toString()
    )

    const nonSelfAssignmentVisibleJobs = nonSelfAssignmentJobs.filter((a) => {
      const isClarizenTaskWithAltairTaskMasterJob =
        a.clarizenTaskExternalId &&
        a.availableFeaturesBillableIndicators?.[0]?.availablefeatures?.features
          .task.required &&
        !a.taskName

      return (
        (a.isJobVisible_V2 === false && !a.taskName) ||
        isClarizenTaskWithAltairTaskMasterJob
      )
    })

    const timesheetJobs = window._.sortBy(
      [...nonSelfAssignmentVisibleJobs, ...deDupJobs],
      ['jobGroupName']
    )

    state.unFilteredShelvedJobs = timesheetJobs

    const filteredShevledJobs = filterItemsByKeyword(
      timesheetJobs,
      state.jobListAssignedJobsSearchKeyword.toLowerCase()
    )

    return filteredShevledJobs
  },

  timesheetStatusStats(state) {
    const results = {
      approved: {
        percent: 0,
        hours: 0,
      },
      rejected: {
        percent: 0,
        hours: 0,
      },
      submitted: {
        percent: 0,
        hours: 0,
      },
      unsubmitted: {
        percent: 0,
        hours: 0,
      },
    }

    let invalidDaysOnWeekDaysCount = 0

    state.calendar.forEach((day) => {
      if (!day.isRequiredByUserContract && !day.isWeekend) {
        invalidDaysOnWeekDaysCount++
      }
    })

    const validDays = 5 - invalidDaysOnWeekDaysCount

    state.timesheets.forEach((a) => {
      if (a.timesheetStatus) {
        if (a.timesheetStatus === 'Approved') {
          results.approved.hours += parseFloat(a.durationValue)
          results.approved.percent +=
            parseFloat(a.durationPercentageValue) / validDays
        }
        if (a.timesheetStatus === 'Rejected') {
          results.rejected.hours += parseFloat(a.durationValue)
          results.rejected.percent +=
            parseFloat(a.durationPercentageValue) / validDays
        }

        if (a.timesheetStatus === 'Submitted') {
          results.submitted.hours += parseFloat(a.durationValue)
          results.submitted.percent +=
            parseFloat(a.durationPercentageValue) / validDays
        }

        if (a.timesheetStatus === 'Unsubmitted') {
          results.unsubmitted.hours += parseFloat(a.durationValue)
          results.unsubmitted.percent +=
            parseFloat(a.durationPercentageValue) / validDays
        }
      }
    })

    return results
  },

  isWeekStartDateBeforeMinDateForTimesheetNavigation: (state, getters) => {
    return moment(getters.selectedDate.startOfWeek).isSameOrBefore(
      state.minDateForTimesheetNavigation,
      'day'
    )
  },

  timeEntryDrawerCSSWidth(state) {
    return state.isTimeEntryDrawerExpanded
      ? 'max(620px, 40%)'
      : state.isTimeEntryDrawerOpen
      ? 'max(320px, 20%)'
      : 0
  },

  VMainRightOffset(state) {
    return state.isTimeEntryDrawerExpanded
      ? '0'
      : state.isTimeEntryDrawerOpen
      ? 'max(320px, 20%)'
      : 0
  },

  getRejectedCorrectedTimesheets(state) {
    const timesheets = state.timesheetExists.filter(
      (t) =>
        t.selfCorrectedHours < 0 &&
        t.timesheetStatus === 'Rejected' &&
        t.selfCorrectedID === state.timesheetDetail?.id
    )

    if (!isEmpty(timesheets) && state.agencyData.enableSelfCorrection) {
      return timesheets
    } else {
      return []
    }
  },

  isLocationActiveForThisWeek(state, getters) {
    const shouldLocationBeActive = Object.values(state.agenciesData).some(
      (agencyData) => {
        const isLocationActiveForThisAgency =
          agencyData.isLocationActive &&
          moment(agencyData.locationEnableDate || '0001-01-01').isSameOrBefore(
            getters.selectedDate.endOfWeek
          )

        return isLocationActiveForThisAgency
      }
    )

    return shouldLocationBeActive
  },

  isLocationActiveForThisDay(state) {
    return (date) => {
      const agencyActiveOnThisDay = state.userContracts.find((contract) => {
        return contract.date.split('T')[0] === date.split('T')[0]
      }).agencyCode

      const agencyData = state.agenciesData[agencyActiveOnThisDay]
      return (
        agencyData.isLocationActive &&
        moment(agencyData.locationEnableDate || '0001-01-01').isSameOrBefore(
          date
        )
      )
    }
  },

  getActiveAgencyData(state) {
    return (date) => {
      const agencyActiveOnThisDay = state.userContracts.find((contract) => {
        return contract.date.split('T')[0] === date.split('T')[0]
      }).agencyCode

      return state.agenciesData[agencyActiveOnThisDay]
    }
  },

  mergedPendingLocationAndWFHSettings(state) {
    const locationSetting = state.pendingUserLocationSettings?.settings?.[0]
    const wfhSetting = state.pendingUserWFHSettings?.settings?.[0]
    let payload = []

    if (!locationSetting && !wfhSetting) return { settings: payload }

    if (locationSetting && wfhSetting) {
      if (locationSetting.id === wfhSetting.id) {
        payload = [
          {
            ...locationSetting,
            ...wfhSetting,
            location: locationSetting.location,
            wfh: wfhSetting.wfh,
          },
        ]
      } else {
        payload = [locationSetting, wfhSetting]
      }
    } else if (locationSetting) {
      payload = [locationSetting]
    } else if (wfhSetting) {
      payload = [wfhSetting]
    }

    return { settings: payload }
  },

  disableWorkCode(state, getters) {
    if (getters.isTimesheetApproved) {
      return true
    }

    if (
      state.timesheetDetail.brandName === 'HOUSE' &&
      state.timesheetDetail.timesheetStatus !== 'Submitted'
    ) {
      return false
    }
    if (
      state.timesheetDetail.brandName === 'HOUSE' &&
      state.timesheetDetail.timesheetStatus === 'Submitted'
    ) {
      return true
    }

    if (state.timesheetDetailFeatures.workcode.data.workCodes.length === 1) {
      return true
    }

    if (state.timesheetDetailFeatures.workcode.data.workCodes.length > 1) {
      return !!state.timesheetDetailFeatures.workcode.data.workCodes.find(
        (workcode) => workcode.selected
      )
    }

    if (state.agencyData.isRigidAgency) {
      //for rigid agency
      if (
        !state.timesheetSelectedJob.job.isClarizenTask ||
        !state.timesheetSelectedJob.job.selfAssignmentWithManageTasking
      ) {
        return true
      }
      if (!state.selectedPPMTask) {
        // non assigned project
        return true
      } else {
        //for assigned project
        return true
      }
    } else {
      //for non rigid agency
      if (!state.selectedPPMTask) {
        // non assigned project
        if (state.selectedPPMTask.jobTitleForBilling) {
          return true
        }
      } else {
        //for assigned project
        if (
          state.selectedPPMTask.resourceDetails &&
          !state.timesheetDetailFeatures.clarizenCustomTask.required &&
          !state.isSelfAssignmentv1_4
        ) {
          return true
        } else if (state.selectedPPMTask.jobTitleForBilling) {
          return true
        }
      }
    }
    return false
  },

  currentDayJobFeatures() {
    return (job, date) => {
      return (
        job?.availableFeaturesBillableIndicators?.find(
          (feat) =>
            moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
            moment(date).format('DD-MM-YYYY')
        ) || {}
      )
    }
  },

  isMidWeekAgencyChange(state) {
    return Object.keys(state.agenciesData).length > 1
  },
}
